import React, { useEffect, useRef, useState } from "react";
import GridItem from "../../components/Grid/GridItem";
import Barcode from "react-barcode";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import { useReactToPrint } from "react-to-print";

const PurchaseBarcodes = (props) => {
  const componentRef = useRef();
  let { purchases } = props.location.state;
  let [barcodes, setBarcodes] = useState([]);
  let [barcodeQuantity, setBarcodeQuantity] = useState();

  console.log("purr", barcodes)

  useEffect(() => {
    let barcodes = [];
    let bcq = 0;
    purchases.map((i) => {
      i.products.map((o) => {
        barcodes.push(o);
        bcq = Number(o.quantity) * 2;
      });
    });
    setBarcodes(barcodes);
    setBarcodeQuantity(bcq)
  }, []);


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: ` 
    @media print {  
        @page {
            size: 222px 94px;
            margin: 0mm;
            padding: 0mm;
            font-size: 14px;
        } 
    }`,
  });

  return (
    <div>
      <Button color="primary" onClick={handlePrint}>
        Print Barcodes
      </Button>
      <Grid ref={componentRef} container style={{ alignItems: "center" }}>
        {Array.from({ length: barcodeQuantity }, (_, index) => (
          <React.Fragment key={index}>
            {barcodes.map((i) => {
              // return (
              //   <>
              //     <GridItem xs={12} md={12} sm={12} style={{ width: 300 }}>

              //       <Barcode value={i.productCode} height={24} width={1} fontSize={11} />

              //       <GridItem xs={12} md={12} sm={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 300 }}>
              //         <GridItem xs={1} sm={1} md={1} >
              //           {/* <Typography style={{ fontSize: "12px" }}>SPH</Typography> */}
              //         </GridItem>
              //         <GridItem xs={2} sm={2} md={2} >
              //           <Typography style={{ fontSize: "12px" }}><b>SPH</b></Typography>
              //         </GridItem>
              //         <GridItem xs={2} sm={2} md={2}>
              //           <Typography style={{ fontSize: "12px" }}><b>CYL</b></Typography>
              //         </GridItem>
              //         <GridItem xs={2} sm={2} md={2}>
              //           <Typography style={{ fontSize: "12px" }}><b>AXIS</b></Typography>
              //         </GridItem>
              //         <GridItem xs={2} sm={2} md={2}>
              //           <Typography style={{ fontSize: "12px" }}><b>ADD</b></Typography>
              //         </GridItem>
              //         <GridItem xs={2} sm={2} md={2}>
              //           <Typography style={{ fontSize: "12px" }}><b>SIDE</b></Typography>
              //         </GridItem>

              //       </GridItem>
              //       <GridItem xs={12} md={12} sm={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 300 }}>
              //         <GridItem xs={1} sm={1} md={1} >
              //           {/* <Typography style={{ fontSize: "12px" }}>SPH</Typography> */}
              //         </GridItem>
              //         <GridItem xs={2} sm={2} md={2} >
              //           <Typography style={{ fontSize: "12px" }}><b>{i.sph}</b></Typography>
              //         </GridItem>
              //         <GridItem xs={2} sm={2} md={2}>
              //           <Typography style={{ fontSize: "12px" }}><b>{i.cyl}</b></Typography>
              //         </GridItem>
              //         <GridItem xs={2} sm={2} md={2}>
              //           <Typography style={{ fontSize: "12px" }}><b>{i.axis}</b></Typography>
              //         </GridItem>
              //         <GridItem xs={2} sm={2} md={2}>
              //           <Typography style={{ fontSize: "12px" }}><b>{i.addition}</b></Typography>
              //         </GridItem>
              //         <GridItem xs={2} sm={2} md={2}>
              //           <Typography style={{ fontSize: "12px" }}><b>{i.side}</b></Typography>
              //         </GridItem>

              //       </GridItem>
              //       <Box xs={12} md={12} sm={12} style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: 300 }}>
              //         <Typography variant="bold" style={{ fontSize: "13px" }}><b>{i.productName}</b></Typography>
              //       </Box>
              //     </GridItem>
              //   </>
              // );
              return (
  <>
    <GridItem xs={12} md={12} sm={12} style={{ width: 300, textAlign: 'center' }}>
      <Barcode value={i.productCode} height={24} width={1} fontSize={11} />

      <GridItem xs={12} md={12} sm={12} 
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 300, marginTop: 4 }}>
        {["SPH", "CYL", "AXIS", "ADD", "SIDE"].map((label, index) => (
          <GridItem key={index} xs={2} sm={2} md={2} style={{ textAlign: 'center' }}>
            <Typography style={{ fontSize: "12px", fontWeight: 'bold' }}>{label}</Typography>
          </GridItem>
        ))}
      </GridItem>

      <GridItem xs={12} md={12} sm={12} 
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 300, marginTop: 2 }}>
        {[i.sph, i.cyl, i.axis, i.addition, i.side].map((value, index) => (
          <GridItem key={index} xs={2} sm={2} md={2} style={{ textAlign: 'center' }}>
            <Typography style={{ fontSize: "12px", fontWeight: 'bold' }}>{value}</Typography>
          </GridItem>
        ))}
      </GridItem>

      <Box xs={12} md={12} sm={12} 
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 300, marginTop: 4 }}>
        <Typography variant="bold" style={{ fontSize: "13px", fontWeight: 'bold' }}>{i.productName}</Typography>
      </Box>
    </GridItem>
  </>
);
            })}
          </React.Fragment>
        ))}
        <hr />

      </Grid>
    </div>
  );
};

export default PurchaseBarcodes;
