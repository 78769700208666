import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { inWords } from "../../utils";
import _ from "lodash";


// THIS IS THE MAIN CHALLAN TEMPLATE

export const challan2Template = (data) => {
    const {
        clientName,
        challanDate,
        items,
        ad,
        challanCode,
        entityName,
        entityId,
        packagingCost,
        runningChallanBalance,
        createdAt,qr
    } = data;

    let code = "";
    if (entityId === "62da46cf7eb6aa02db41297f") {
        code = `VO`;
    } else if (entityId === "62da46c57eb6aa02db41297d") {
        code = `IE`;
    } else {
        code = `VR`;
    }

    let totalAmount = 0;
    let totalQuantity = 0;
    let rows = [];
    console.log(items, "items");
    items.forEach((item, index) => {
        const {
            lProductCode,
            rProductCode,
            nameR,
            nameL,
            quantity,
            amount,
            rateR,
            rateL,
            discountPercent,
            discountAmount,
            fitting,
            fittingAmount,
            tint, arc,
            orderRef
        } = item;

        totalAmount += Number(amount);
        totalQuantity += Number(quantity);

        rows.push([
            index + 1,
            `${nameR ? `R-${nameR}` : ''} ${rProductCode ? "[" + rProductCode.substring(3) + "]" : ""} \n${nameL ? `L-${nameL}` : ''} ${lProductCode ? "[" + lProductCode.substring(3) + "]" : ""}
            ${orderRef ? "\nOrd Ref: " + orderRef : ""}`,


                // `${nameR ? 'R' : ''}\n${nameL ? 'L' : ''}`,
                // `${nameR ? `R-${nameR}` : ''} ${rProductCode
                //     ? "[" + rProductCode.substring(3, rProductCode.length) + "]" : ""
                // }\n${nameL ? `L-${nameL}` : ''} ${lProductCode
                //     ? "[" + lProductCode.substring(3, lProductCode.length) + "]" + "\nOrd Ref:" + orderRef
                //     : ""
                // }`,
            quantity,
            `${rateR || ''}\n${rateL || ''}`,
            (rateL && rateR) ? ((Number(rateL) + Number(rateR)) / 2).toFixed(2) : (rateL ? (Number(rateL) / 2).toFixed(2) : (rateR ? (Number(rateR) / 2).toFixed(2) : '')),
            discountPercent ? `${discountPercent}%` : (discountAmount ? `Rs. ${discountAmount}` : ''),
            `${fitting ? `Fitt:${fitting}-Rs.${fittingAmount}` : ''}\n${tint ? `tint:${tint}` : ''}\n${arc ? `ARC:${arc}` : ''}`,
            _.round(amount, 2).toFixed(2)
        ]);

    });
    console.log(rows);
    const totalAmountInWords = inWords(_.round(totalAmount + Number(packagingCost), 2));
    const finalAmount = _.round(Number(packagingCost) + totalAmount, 2).toFixed(2);

    const doc = new jsPDF();


    // Table headers
    const headers = [
        ['Sr No.', 'Name', 'Qty', 'Rate', 'Total', 'Disc', 'Extra Charges', 'Amount']
    ];
    doc.setFontSize(10);

    // Add table with the provided styling
    doc.autoTable({
        head: headers,
        margin: { left: 2, right: 2, top: 2 },
        body: rows,
        startY: 20,
        startX: 10, // Adjusted startX for better alignment
        styles: {
            fontSize: 10,
            cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
            textColor: [0, 0, 0],
            lineWidth: 0.1,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255]
        },
        headStyles: {
            fontStyle: 'bold',
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            valign: 'middle',
            lineWidth: 0.1,
            lineColor: [0, 0, 0],
            fontSize: 8,
        },
        footStyles: {
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            lineWidth: 0.1,
            lineColor: [0, 0, 0]
        },
        tableLineWidth: 0.1,
        tableLineColor: [0, 0, 0],
        columnStyles: {
            0: { cellWidth: 10 }, // Sr No.
            1: { cellWidth: 'auto', }, // Name
            2: { cellWidth: 'auto' }, // Qty
            3: { cellWidth: 'auto' }, // Rate
            4: { cellWidth: 'auto' }, // Total
            5: { cellWidth: 'auto' }, // Disc
            6: { cellWidth: 50 }, // Extra Charges - adjusted width
            7: { cellWidth: 'auto' } // Amount
        },
        didDrawPage: function (data) {
            if (data.pageCount === 1) {
                // Title and header information
                doc.setFontSize(13);
                doc.text(`Approval Memo`, 10, 10);
                doc.text(`${code}`, 100, 10, { align: 'center' });
                doc.text(`Dt: ${createdAt}`, 200, 10, { align: 'right' });
                doc.setFontSize(10);
                doc.text(`M / S: ${clientName}`, 10, 15);
                doc.text(`${challanCode}`, 200, 15, { align: 'right' });
                if(qr){
                    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${challanCode}`;
                    const img = new Image();
                    console.log(img, "<<<<<<img")
                    img.src = qrCodeUrl;
                    img.crossOrigin = "Anonymous"; // Ensures no CORS issues
                    img.onload = function () {
                        console.log("here")
                        const canvas = document.createElement("canvas");
                        const ctx = canvas.getContext("2d");
                        canvas.width = img.width;
                        canvas.height = img.height;
                        ctx.drawImage(img, 0, 0);
                        const qrBase64 = canvas.toDataURL("image/png");
                        console.log(qrBase64, "<<<<<base")
                        // Add the QR code to the PDF
                        doc.addImage(qrBase64, "PNG", 160, 5, 40, 40); // Adjust position & size as needed
                        // Now generate and open the PDF
                        // const pdfBlob = doc.output("blob");
                        // const url = window.URL.createObjectURL(pdfBlob);
                        // window.open(url, "_blank");
                    };
                }
            }
            // Footer
            let footerStr = `Page ${data.pageCount}`;
            doc.text(footerStr, data.settings.margin.left, doc.internal.pageSize.height - 10);
        }
    });


    const finalY = doc.lastAutoTable.finalY;
    const secondTableStartY = doc.lastAutoTable.finalY;
    doc.autoTable({
        showHead: "never",
        margin: { left: 2, right: 2, top: 2 },
        head: headers,
        body: [
            ['Sub Total', '', '', `Total Qt: ${totalQuantity}`, '', '', '', _.round(totalAmount).toFixed(2)],
            ['Packaging Cost', '', '', '', '', '', '', _.round(Number(packagingCost), 2).toFixed(2)],
            ['Total Amount', '', '', '', '', '', '', _.round(totalAmount + Number(packagingCost), 2).toFixed(2)],
            ['Running Challan Balance', '', runningChallanBalance]
        ],
        startY: secondTableStartY,
        startX: 5,
        styles: {
            fontStyle: 'bold',
            fontSize: 12,
            textColor: [0, 0, 0],
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255]
        },
        headStyles: {
            fontStyle: 'bold',
            cellPadding: 0.75,
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            valign: 'middle',
            lineColor: [0, 0, 0],
            fontSize: 12,
        },
        footStyles: {
            cellPadding: 0.75,
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            lineWidth: 0.05,
            lineColor: [0, 0, 0]
        },
        tableLineWidth: 0.1,
        tableLineColor: [0, 0, 0],
        columnStyles: {
            10: { halign: 'right' }, // Right align the last column (index 5)
            4: { halign: 'left' }
        }
    });
    doc.text(`In Words: ${totalAmountInWords}`, 15, secondTableStartY + 40);
    doc.text('E & OE', 15, finalY + 45);
    doc.text('Working Hours: 10.30 AM to 7.00 PM', 15, finalY + 50);
    // Add totals and footer
    // doc.text(`Total Quantity: ${ totalQuantity }`, 15, finalY + 10);
    // doc.text(`Packaging Cost: ${ _.round(Number(packagingCost), 2).toFixed(2) }`, 180, finalY + 10, { align: 'right' });
    // doc.setFontSize(13);

    // doc.text(`Final Amount: ${ finalAmount }`, 180, finalY + 20, { align: 'right' });
    // doc.setFontSize(10);
    // doc.text(`In Words: ${ totalAmountInWords }`, 15, finalY + 30);
    // if (runningChallanBalance) {
    //     doc.text(`Balance Upto Date: ${ _.round(Number(runningChallanBalance), 2).toFixed(2) }`, 200, finalY + 30, { align: 'right' });
    // }
    // doc.text(ad, 10, finalY + 40);

    const pdfBlob = doc.output('blob');

    // Open the PDF in a new browser tab
    const url = window.URL.createObjectURL(pdfBlob);
    window.open(url, '_blank');

    // Save the PDF
    // doc.save('challan2.pdf');
};
