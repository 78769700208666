import React, { useState, useEffect } from "react";
import {
  Dialog,
  Box,
  Slide,
  makeStyles,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TableBody,
  Table,
  TableRow,
  TableHead,
  TableCell,
  Button,
  Snackbar,
} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CustomInput from "../../components/CustomInput/CustomInput";
import axios from "axios";
import { AccountVerificationAPI, baseUrl } from "../../config/api";
import { useSelector } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import { width } from "@mui/system";
import { Typography } from "@mui/material";
import { toast } from "react-toastify"

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let entityTypes = [
  {
    label: "Vijaya Optician",
    value: "Vijaya Optician",
  },
  {
    label: "Infiity Enterprises",
    value: "Infiity Enterprises",
  },
  {
    label: "V.R. Enterprises",
    value: "V.R. Enterprises",
  },
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AccountVerificationView(props) {
  const { open, close, item } = props;
  console.log(item, "this is single item")
  const [accountCode, setAccountCode] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertObject, setAlertObject] = useState(null);
  const authToken = useSelector((state) => state.userReducer.authToken);
  const userInfo = useSelector((state) => state.userReducer.userInfo);
  const classes = useStyles();
  let {
    // accountCode,
    name,
    contact,
    gstNumber,
    panNumber,
    adharNumber,
    address,
    city,
    state,
    pin,
    landline,
    mobile,
    whatsappNumber,
    email,
    shippingAddress,
    users,
    _id,
    accountLogo,
    gstCard,
    visitingCard,
    panCard,
    adharCard,
  } = item;
  const [brandName, setBrandName] = useState("");
  const [entityType, setEntityType] = useState(null);
  async function _onReject() {
    try {
      let body = {
        accountCode,
        users,
        entity: entityType,
        adminVerification: false,
      };
      let res = await axios.patch(AccountVerificationAPI(_id), body, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      toast.success('Successfully Rejected!', {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      close();
    } catch (err) {
      // console.log(err);
      // toast.error(("Something went wrong, Please Contact Admin"), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }
  async function _onSave() {
    try {
      let body = {
        accountCode,
        users,
        entity: entityType,
        adminVerification: true,
      };
      console.log("onsaveitem", _id, item)
      let res = await axios.patch(AccountVerificationAPI(_id), body, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      close();
    } catch (err) {
      // console.log(err.response.data);
      // toast.error(("Something went wrong, Please Contact Admin"), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
      setOpenSnackbar(true);
      setAlertObject({ status: "error", message: err.response.data });
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>Account Name</h4>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="Account Code*"
                    id="accountCode"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: accountCode,
                      onChange: (event) => setAccountCode(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="Name"
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: name,
                      disabled: true,
                    }}
                  />
                </GridItem>

                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="landline"
                    id="landline"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: landline,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="mobile"
                    id="mobile"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: mobile,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="whatsappNumber"
                    id="whatsappNumber"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: whatsappNumber,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: email,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="gstNumber"
                    id="gstNumber"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: gstNumber,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="panNumber"
                    id="panNumber"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: panNumber,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="adharNumber"
                    id="adharNumber"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: adharNumber,
                      disabled: true,
                    }}
                  />
                </GridItem>

                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="address"
                    id="address"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: address,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="city"
                    id="city"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: city,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="state"
                    id="state"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: state,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="pin"
                    id="pin"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: pin,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="shippingAddress"
                    id="shippingAddress"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: shippingAddress[0].address,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem
                  xs={6}
                  sm={6}
                  md={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Entity Type*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={entityType}
                      label="Type"
                      onChange={(e) => {
                        e.preventDefault();
                        setEntityType(e.target.value);
                      }}
                    >
                      {entityTypes.map(({ label, value }) => {
                        return <MenuItem value={value}>{label}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                {accountLogo ? (
                  <GridItem xs={4}>
                    <Typography
                      style={{ textAlign: "center", margin: "20px 0px" }}
                    >
                      Account Logo
                    </Typography>
                    <img
                      // src={`${baseUrl}/uploads/${accountLogo}`}
                      src={`${baseUrl}${accountLogo}`}
                      style={{
                        height: 200,
                        width: 200,
                        display: "block",
                        margin: "auto",
                      }}
                    />
                  </GridItem>
                ) : null}
                {visitingCard ? (
                  <GridItem xs={4}>
                    <Typography
                      style={{ textAlign: "center", margin: "20px 0px" }}
                    >
                      Visiting Card
                    </Typography>
                    <img
                      // src={`${baseUrl}/uploads/${visitingCard}`}
                      src={`${baseUrl}${accountLogo}`}

                      style={{
                        height: 200,
                        width: 200,
                        display: "block",
                        margin: "auto",
                      }}
                    />
                  </GridItem>
                ) : null}
                {panCard ? (
                  <GridItem xs={4}>
                    <Typography
                      style={{ textAlign: "center", margin: "20px 0px" }}
                    >
                      Pan Card
                    </Typography>
                    <img
                      // src={`${baseUrl}/uploads/${panCard}`}
                      src={`${baseUrl}${accountLogo}`}

                      style={{
                        height: 200,
                        width: 200,
                        display: "block",
                        margin: "auto",
                      }}
                    />
                  </GridItem>
                ) : null}
                {adharCard ? (
                  <GridItem xs={4}>
                    <Typography
                      style={{ textAlign: "center", margin: "20px 0px" }}
                    >
                      Adhar Card
                    </Typography>
                    <img
                      // src={`${baseUrl}/uploads/${adharCard}`}
                      src={`${baseUrl}${accountLogo}`}

                      style={{
                        height: 200,
                        width: 200,
                        display: "block",
                        margin: "auto",
                      }}
                    />
                  </GridItem>
                ) : null}
                {gstCard ? (
                  <GridItem xs={4}>
                    <Typography
                      style={{ textAlign: "center", margin: "20px 0px" }}
                    >
                      GST Card
                    </Typography>
                    <img
                      // src={`${baseUrl}/uploads/${gstCard}`}
                      src={`${baseUrl}${accountLogo}`}

                      style={{
                        height: 200,
                        width: 200,
                        display: "block",
                        margin: "auto",
                      }}
                    />
                  </GridItem>
                ) : null}

                <GridItem xs={12} style={{ marginTop: 15 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>User Name</TableCell>
                        <TableCell>User Phone Number</TableCell>
                        <TableCell>User Email</TableCell>
                        <TableCell>User Address</TableCell>
                        <TableCell>User Role</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users?.map((user, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell>{user.name}</TableCell>
                            <TableCell>{user.mobile}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.address}</TableCell>

                            <TableCell>{user.role}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: 15,
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => _onReject()}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (accountCode === "" || entityType === null) {
                        toast.error("Please fill the required fields", {
                          position: "top-center",
                          autoClose: 1500,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                        return;
                      }

                      _onSave();
                    }}
                  >
                    Accept
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alertObject ? (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={alertObject.status}>
            {alertObject.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Dialog>
  );
}
