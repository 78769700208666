import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import { Search, People, FilterList as FilterIcon } from "@material-ui/icons";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CustomInput from "../../components/CustomInput/CustomInput";
import { baseUrl, GetAllBrandsAPI, GetAllProdLogAPI } from "../../config/api";
import { toast } from "react-toastify";
import PrintIcon from "@mui/icons-material/Print";
import jsPDF from "jspdf";
import { Autocomplete, createFilterOptions } from "@mui/material";
// import { Dialog, DialogTitle, DialogContent, DialogActions, Box, TextField, Button, Autocomplete } from '@mui/material';
// import { DatePicker } from '@mui/lab';

const useStyles = makeStyles((theme) => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
  },
}));

const columns = [
  { id: "productCode", label: "Product Code" },
  { id: "productName", label: "Product Name" },
  {
    id: "totalPrice",
    label: "Price",
    format: (value) =>
      Number(value).toLocaleString("en-IN", {
        maximumFractionDigits: 2,
        style: "currency",
        currency: "INR",
      }),
  },
  { id: "totalQuantity", label: "Quantity" },
  { id: "status", label: "Status" },
];

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 500,
});

export default function ProdLogList() {
  const classes = useStyles();
  const authToken = useSelector((state) => state.userReducer.authToken);
  const [accounts, setAccounts] = useState([]);
  console.log(accounts);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [brand, setBrand] = useState([]);
  const [color, setColor] = useState("");
  const userInfo = useSelector((state) => state.userReducer.userInfo);

  useEffect(() => {
    fetchBrands();
    fetchProductLogs(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const fetchBrands = async () => {
    try {
      const res = await axios.get(GetAllBrandsAPI(userInfo.entity), {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setBrand(res.data.brandMasters);
    } catch (err) {
      toast.error("Something went wrong, Please Contact Admin");
    }
  };

  const fetchProductLogs = async (page, rowsPerPage) => {
    const skip = page * rowsPerPage;
    const query = `?limit=${rowsPerPage}&skip=${skip}`;
    try {
      const res = await axios.get(`${GetAllProdLogAPI()}${query}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setAccounts(res.data.productLog || []);
      setTotal(res.data.total || 0);
    } catch (err) {
      toast.error("Something went wrong, Please Contact Admin");
    }
  };

  // const handleApplyFilter = () => {
  //   const filteredAccounts = accounts.filter((item) => {
  //     console.log(item, "item", item?.productCode?.substring(0, 3), color);
  //     const dbDate = new Date(item.createdAt);
  //     console.log(startDate, endDate, color, selectedBrand, "<<<<<<COLOR");
  //     return (
  //       (dbDate >= new Date(startDate) &&
  //         dbDate <= new Date(endDate) &&
  //         !color) ||
  //       (item?.productCode?.substring(0, 3) === color && !selectedBrand) ||
  //       item?.brand?.name === selectedBrand?.name
  //     );
  //   });
  //   console.log(filteredAccounts, "<<<<<<filteredAccounts");
  //   setAccounts(filteredAccounts);
  //   setTotal(filteredAccounts.length);
  //   setOpenFilter(false);
  // };

  const handleApplyFilter = () => {
    const filteredAccounts = accounts.filter((item) => {
      console.log(item, "<<<<<item")
      const dbDate = new Date(item.createdAt);
      const productCodePrefix = item?.productCode?.substring(0, 3);
      const isDateInRange =
        dbDate >= new Date(startDate) && dbDate <= new Date(endDate);
      const isColorMatch = color && productCodePrefix === color;
      const isBrandMatch =
        selectedBrand && item?.brand?.name === selectedBrand.name;

      // Check the filter criteria
      return (
        (isDateInRange && !color && !selectedBrand) || // Date-only filter
        (isColorMatch && !selectedBrand) || // Color-only filter
        (isBrandMatch && !isDateInRange && !color) || // Brand-only filter
        (isDateInRange && isColorMatch && isBrandMatch) // All filters combined
      );
    });

    console.log("Filtered Accounts:", filteredAccounts);
    setAccounts(filteredAccounts);
    setTotal(filteredAccounts.length);
    setOpenFilter(false);
  };

  const handleSearch = () => {
    const filteredAccounts = accounts.filter((account) =>
      account?.productName?.toLowerCase().includes(search?.toLowerCase())
    );
    setAccounts(filteredAccounts);
    setTotal(filteredAccounts.length);
  };

  // const handlePDFExport = () => {
  //   const doc = new jsPDF();
  //   doc.text("Product Log", doc.internal.pageSize.getWidth() / 2, 5);
  //   doc.autoTable({
  //     head: [["Product Code-Name", "Quantity", "Price"]],
  //     body: accounts.map((item) => [
  //       `${item.productName} [${item.productCode}]`,
  //       item.quantity,
  //       item.price,
  //     ]),
  //     startY: 10,
  //   });
  //   doc.save("ProductLog.pdf");
  // };

  // async function _onPreview() {
  //   try {
  //     const doc = new jsPDF();
      
  //     // Check if dataaa is empty
  //     if (accounts.length === 0) {
  //       doc.text("No product log available.", 10, 10);
  //       const pdfBlob = doc.output("blob");
  //       const url = window.URL.createObjectURL(pdfBlob);
  //       window.open(url, "_blank");
  //       return;
  //     }

  //     // Define title and date range
  //     const title = "Product Log Report";
  //     const dateRange = `From: ${startDate} To: ${endDate}`;
      
  //     const TITLE_FONT_SIZE = 14;
  //     const DATE_FONT_SIZE = 10;

  //     // Add title to the top of the document
  //     doc.setFontSize(TITLE_FONT_SIZE);
  //     const titleWidth = doc.getStringUnitWidth(title) * TITLE_FONT_SIZE / doc.internal.scaleFactor;
  //     const titleXPosition = (doc.internal.pageSize.getWidth() - titleWidth) / 2;
  //     doc.text(title, titleXPosition, 20);

  //     // Add date range below the title
  //     doc.setFontSize(DATE_FONT_SIZE);
  //     const dateRangeWidth = doc.getStringUnitWidth(dateRange) * DATE_FONT_SIZE / doc.internal.scaleFactor;
  //     const dateXPosition = (doc.internal.pageSize.getWidth() - dateRangeWidth) / 2;
  //     doc.text(dateRange, dateXPosition, 30);
  
  //     // Font size constants
  //     const PRODUCT_FONT_SIZE = 12;
  //     const TABLE_FONT_SIZE = 10;
  //     const HEADER_FONT_SIZE = 8;

  //     console.log(accounts, "accounts")
  
  //     // Add each product's details
  //     for (const item of accounts) {
  //       const productLabel = `${item.productName} [${item.productCode}]`;
  
  //       // Set the position for the product name
  //       const textWidth = (doc.getStringUnitWidth(productLabel) * PRODUCT_FONT_SIZE) / doc.internal.scaleFactor;
  //       const xPosition = (doc.internal.pageSize.getWidth() - textWidth) / 2;
  //       const yPosition = (doc.autoTable.previous.finalY + 10) || 10; // Adjust Y position based on previous table
  
  //       // Add product name
  //       doc.text(productLabel, xPosition, yPosition);
  
  //       // Prepare table data
  //       const tableData = [
  //         [
  //           item.sph,
  //           item.cyl,
  //           item.axis,
  //           item.add,
  //           item.quantity,
  //         ],
  //       ];
  
  //       // Add the table
  //       doc.autoTable({
  //         // head: tableData[0],
  //         head: [
  //         ["SPH", "CYL", "AXIS", "Addition", "Quantity"],
  //         ],
  //         body: [tableData[0]],
  //         startY: yPosition + 10, // Position the table below the product name
  //         styles: {
  //           fontSize: TABLE_FONT_SIZE,
  //           cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
  //           textColor: [0, 0, 0],
  //           lineWidth: 0.1,
  //           lineColor: [0, 0, 0],
  //           fillColor: [255, 255, 255],
  //         },
  //         headStyles: {
  //           fontStyle: "bold",
  //           fillColor: [200, 200, 200],
  //           textColor: [0, 0, 0],
  //           valign: "middle",
  //           lineWidth: 0.1,
  //           lineColor: [0, 0, 0],
  //           fontSize: HEADER_FONT_SIZE,
  //         },
  //         didDrawPage: function (data) {
  //           // let footerStr = `Page ${data.pageCount}`;
  //           // const footerX = (doc.internal.pageSize.getWidth() - doc.getStringUnitWidth(footerStr) * TABLE_FONT_SIZE / doc.internal.scaleFactor) / 2;
  //           // doc.text(footerStr, footerX, doc.internal.pageSize.height - 10);
  //         },
  //       });
  //     }
  
  //     // Output the PDF as a Blob
  //     const pdfBlob = doc.output("blob");
  //     const url = window.URL.createObjectURL(pdfBlob);
  //     window.open(url, "_blank");
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //   }
  // }

  async function _onPreview() {
    try {
      const doc = new jsPDF();

      let accs = accounts.sort((a, b) => {
        if (a.productName > b.productName) return 1;
        if (a.productName < b.productName) return -1;
        return 0;
      });
      console.log(accs, "<<<<accs")
      // Check if accounts data is empty
      if (accs.length === 0) {
        doc.text("No product log available.", 10, 10);
        const pdfBlob = doc.output("blob");
        const url = window.URL.createObjectURL(pdfBlob);
        window.open(url, "_blank");
        return;
      }
  
      // Define title and date range
      const title = "Product Log Report";
      const dateRange = startDate && endDate ? `From: ${startDate} To: ${endDate}` : null;
      
      const TITLE_FONT_SIZE = 14;
      const DATE_FONT_SIZE = 10;
      const PRODUCT_FONT_SIZE = 12;
      const TABLE_FONT_SIZE = 10;
      const HEADER_FONT_SIZE = 8;
      
      // Add title to the top of the document
      doc.setFontSize(TITLE_FONT_SIZE);
      const titleWidth = doc.getStringUnitWidth(title) * TITLE_FONT_SIZE / doc.internal.scaleFactor;
      const titleXPosition = (doc.internal.pageSize.getWidth() - titleWidth) / 2;
      doc.text(title, titleXPosition, 20);


       // Add date range below the title if present
    if (dateRange) {
      doc.setFontSize(DATE_FONT_SIZE);
      const dateRangeWidth = doc.getStringUnitWidth(dateRange) * DATE_FONT_SIZE / doc.internal.scaleFactor;
      const dateXPosition = (doc.internal.pageSize.getWidth() - dateRangeWidth) / 2;
      doc.text(dateRange, dateXPosition, 30);
    }
      
      // Initial Y position for product data
      let currentYPosition = 40;
    
      // Add each product's details
      for (const item of accounts) {
        // Add product name
        const productLabel = `${item.productName} [${item.productCode}]`;
        doc.setFontSize(PRODUCT_FONT_SIZE);
        const productTextWidth = doc.getStringUnitWidth(productLabel) * PRODUCT_FONT_SIZE / doc.internal.scaleFactor;
        const productXPosition = (doc.internal.pageSize.getWidth() - productTextWidth) / 2;
        doc.text(productLabel, productXPosition, currentYPosition);
        
        // Prepare table data
        const tableData = [
          [
            item.sph,
            item.cyl,
            item.axis,
            item.add,
            item.quantity,
          ],
        ];
    
        // Add the table for the product below the product name
        doc.autoTable({
          head: [["SPH", "CYL", "AXIS", "Addition", "Quantity"]],
          body: tableData,
          startY: currentYPosition + 10, // Position the table below the product label
          styles: {
            fontSize: TABLE_FONT_SIZE,
            cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
            textColor: [0, 0, 0],
            lineWidth: 0.1,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
          },
          headStyles: {
            fontStyle: "bold",
            fillColor: [200, 200, 200],
            textColor: [0, 0, 0],
            valign: "middle",
            lineWidth: 0.1,
            lineColor: [0, 0, 0],
            fontSize: HEADER_FONT_SIZE,
          },
        });
        
        // Update Y position based on the new table position
        currentYPosition = doc.autoTable.previous.finalY + 15;
        
        // Check for page overflow
        if (currentYPosition > doc.internal.pageSize.getHeight() - 20) {
          doc.addPage();
          currentYPosition = 20;
        }
      }
    
      // Output the PDF as a Blob
      const pdfBlob = doc.output("blob");
      const url = window.URL.createObjectURL(pdfBlob);
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  }

//   async function _onPreview() {
//     try {
//         const doc = new jsPDF();

//         let accs = accounts.sort((a, b) => {
//             if (a.productName > b.productName) return 1;
//             if (a.productName < b.productName) return -1;
//             return 0;
//         });
//         console.log(accs, "<<<<accs");

//         // Check if accounts data is empty
//         if (accs.length === 0) {
//             doc.text("No product log available.", 10, 10);
//             const pdfBlob = doc.output("blob");
//             const url = window.URL.createObjectURL(pdfBlob);
//             window.open(url, "_blank");
//             return;
//         }

//         // Define title and date range
//         const title = "Product Log Report";
//         const dateRange = startDate && endDate ? `From: ${startDate} To: ${endDate}` : null;

//         const TITLE_FONT_SIZE = 12; // Font size for the title
//         const DATE_FONT_SIZE = 8;    // Font size for the date
//         const PRODUCT_FONT_SIZE = 10; // Font size for product names
//         const TABLE_FONT_SIZE = 8;    // Font size for table content
//         const HEADER_FONT_SIZE = 8;    // Font size for table headers

//         // Add title to the top of the document
//         doc.setFontSize(TITLE_FONT_SIZE);
//         const titleWidth = doc.getStringUnitWidth(title) * TITLE_FONT_SIZE / doc.internal.scaleFactor;
//         const titleXPosition = (doc.internal.pageSize.getWidth() - titleWidth) / 2; // Centered title
//         doc.text(title, titleXPosition, 15);

//         // Add date range below the title if present
//         if (dateRange) {
//             doc.setFontSize(DATE_FONT_SIZE);
//             const dateRangeWidth = doc.getStringUnitWidth(dateRange) * DATE_FONT_SIZE / doc.internal.scaleFactor;
//             const dateXPosition = (doc.internal.pageSize.getWidth() - dateRangeWidth) / 2; // Centered date
//             doc.text(dateRange, dateXPosition, 25);
//         }

//         // Initial Y position for product data
//         let currentYPosition = 30;

//         // Add each product's details
//         for (const item of accounts) {
//             // Add product name
//             const productLabel = `${item.productName} [${item.productCode}]`;
//             doc.setFontSize(PRODUCT_FONT_SIZE);
//             const productTextWidth = doc.getStringUnitWidth(productLabel) * PRODUCT_FONT_SIZE / doc.internal.scaleFactor;
//             const productXPosition = (doc.internal.pageSize.getWidth() - productTextWidth) / 2; // Centered product label
//             doc.text(productLabel, productXPosition, currentYPosition);

//             // Prepare table data
//             const tableData = [
//                 [
//                     item.sph,
//                     item.cyl,
//                     item.axis,
//                     item.add,
//                     item.quantity,
//                 ],
//             ];

//             // Add the table for the product below the product name
//             doc.autoTable({
//                 head: [["SPH", "CYL", "AXIS", "Addition", "Quantity"]],
//                 body: tableData,
//                 startY: currentYPosition + 10, // Position the table below the product label
//                 styles: {
//                     fontSize: TABLE_FONT_SIZE,
//                     cellPadding: { top: 1, right: 1, bottom: 1, left: 1 }, // Reduced padding
//                     textColor: [0, 0, 0],
//                     lineWidth: 0.1,
//                     lineColor: [0, 0, 0],
//                     fillColor: [255, 255, 255],
//                 },
//                 headStyles: {
//                     fontStyle: "bold",
//                     fillColor: [200, 200, 200],
//                     textColor: [0, 0, 0],
//                     valign: "middle",
//                     lineWidth: 0.1,
//                     lineColor: [0, 0, 0],
//                     fontSize: HEADER_FONT_SIZE,
//                 },
//                 columnStyles: { // Add column width management if needed
//                     0: { cellWidth: 20 }, // Example: narrow SPH column
//                     1: { cellWidth: 20 }, // Example: narrow CYL column
//                     2: { cellWidth: 20 }, // Example: narrow AXIS column
//                     3: { cellWidth: 20 }, // Example: narrow Addition column
//                     4: { cellWidth: 20 }, // Example: narrow Quantity column
//                 },
//             });

//             // Update Y position based on the new table position
//             currentYPosition = doc.autoTable.previous.finalY + 10; // Adjusted space after table

//             // Check for page overflow
//             if (currentYPosition > doc.internal.pageSize.getHeight() - 20) {
//                 doc.addPage();
//                 currentYPosition = 15; // Reset Y position for new page
//             }
//         }

//         // Output the PDF as a Blob
//         const pdfBlob = doc.output("blob");
//         const url = window.URL.createObjectURL(pdfBlob);
//         window.open(url, "_blank");
//     } catch (error) {
//         console.error("Error generating PDF:", error);
//     }
// }

  
  
  const handleClearFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setColor("");
    setSelectedBrand(null);
    fetchProductLogs(page, rowsPerPage);
    setOpenFilter(false);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <Box display="flex" justifyContent="space-between">
                <h4 className={classes.cardTitleWhite}>Product Logs</h4>
                <Box display="flex">
                  <CustomInput
                    id="search"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      placeholder: "Search",
                      value: search,
                      onChange: (e) => setSearch(e.target.value),
                      endAdornment: (
                        <InputAdornment position="end">
                          <People />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <IconButton color="secondary" onClick={handleSearch}>
                    <Search />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => setOpenFilter(true)}
                  >
                    <FilterIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={_onPreview}>
                    <PrintIcon />
                  </IconButton>
                </Box>
              </Box>
            </CardHeader>
            <CardBody>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align="center">
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accounts
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow hover key={row.productCode}>
                        {columns.map((column) => (
                          <TableCell key={column.id} align="center">
                            {column.format && typeof row[column.id] === "number"
                              ? column.format(row[column.id])
                              : row[column.id]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) =>
                  setRowsPerPage(+event.target.value)
                }
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <Dialog open={openFilter} onClose={() => setOpenFilter(false)} fullWidth maxWidth="sm">
  <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: 'primary.main' }}>
    Filter by Date, Color, and Brand
  </DialogTitle>
  <DialogContent dividers>
    <Box display="flex" flexDirection="column" gap={3}>
      <DatePicker
        label="Start Date"
        value={startDate}
        sx={{paddingBottom:2}}
        onChange={(date) => setStartDate(date)}
        renderInput={(props) => <TextField {...props} fullWidth />}
      />
      <DatePicker
        label="End Date"
        value={endDate}
        onChange={(date) => setEndDate(date)}
        renderInput={(props) => <TextField {...props} fullWidth />}
      />
      <TextField
        label="Color"
        value={color}
        onChange={(e) => setColor(e.target.value)}
        fullWidth
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
          }
        }}
      />
      <Autocomplete
        filterOptions={filterOptions}
        loading={!brand.length}
        options={brand}
        value={selectedBrand}
        onChange={(event, newValue) => setSelectedBrand(newValue)}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Brand"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              }
            }}
          />
        )}
      />
    </Box>
  </DialogContent>
  <DialogActions sx={{ padding: 2, justifyContent: 'space-between' }}>
    <Button onClick={handleClearFilter} variant="outlined" color="primary">
      Clear Filter
    </Button>
    <Box display="flex" gap={2}>
      <Button onClick={() => setOpenFilter(false)} color="inherit">
        Cancel
      </Button>
      <Button onClick={handleApplyFilter} variant="contained" color="primary">
        Apply
      </Button>
    </Box>
  </DialogActions>
</Dialog>
    </>
  );
}
