import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import _ from "lodash";

let options = {
  orientation: "p",
  unit: "pt",
  format: [850, 1066],
  putOnlyUsedFonts: true,
};

export const ledgerTemplate = (data) => {
  let {
    ledgers,
    startDate,
    endDate,
    clientName,
    closingBalance,
    ledgerType,
    openingBalance,
    pay,
  } = data;
  console.log("dataled", data);

  openingBalance = openingBalance || 0;
  let tableData = [];
  let balance = openingBalance ? openingBalance : 0;

  console.log(ledgers, "<<<<<ledgers", tableData);
  ledgers.map((o, index) => {
    let { codes, amount, type, date, code, paymentFor } = o;
    console.log("amt>>>>", amount, paymentFor, type, balance);

    // Adjust balance based on type
    if (type === "credit") {
      balance -= Number(amount);
      console.log("subbed", amount, "from" ,balance)
    }
    if (type === "debit") {
      balance += Number(amount);
      console.log("added", amount, "to" ,balance)

    }
    if (paymentFor?.toLowerCase().includes("sale")) {
      balance -= Number(amount); // Add amount for Sale
    } else if (paymentFor?.toLowerCase().includes("purchase")) {
      balance += Number(amount); // Subtract amount for Purchase
    } else {
      console.log("keeping same", "noapply")
      // balance = Number(amount);
    }
    // if (paymentFor.toLowerCase().includes("sale")) {
    //   balance += Number(amount); // Add amount for Sale
    // } else if (paymentFor.toLowerCase().includes("purchase")) {
    //   balance -= Number(amount); // Subtract amount for Purchase
    // }

    if (pay) {
      console.log("ithun")
      // Logic for pay mode, incorporating new conditions for credit and debit columns based on paymentFor
      let row = [
        index + 1,
        moment(date).format("DD-MM-YYYY"),
        code,
        // balance.toFixed(2),
        // Check if paymentFor includes "sale" or "purchase" for credit and debit columns
        paymentFor?.toLowerCase().includes("sale") ? Math.round(amount) : "", // Credit column if paymentFor contains "Sale"
        paymentFor?.toLowerCase().includes("purchase") ? Math.round(amount) : "", // Debit column if paymentFor contains "Purchase"
        // balance.toFixed(2),
        Math.round(balance),
      ];
      tableData.push(row);
    } else {
      console.log("tithun")
      // Logic for non-pay mode, keeping the original condition for credit, debit, and payment types
      let row = [
        index + 1,
        moment(date).format("DD-MM-YYYY"),
        codes[0],
        type === "credit" ? Math.round(amount) : "", // Credit column based on type
        type === "debit" ? Math.round(amount) : "", // Debit column based on type
        // type === "payment" ? amount : "", // Payment column based on type
        // balance.toFixed(2),
        Math.round(balance),
      ];
      tableData.push(row);
    }
  });

  let doc = new jsPDF(options);

  // doc.setFontSize(14);
  // doc.text(`Ledger - ${ledgerType}`, 40, 30);

  // if (startDate != null && endDate != null) {
  //     doc.text(`${moment(startDate).format("DD-MM-YYYY")} to ${moment(endDate).format("DD-MM-YYYY")}`, 600, 30);
  // }

  // doc.text(`M/S: ${clientName}`, 40, 50);
  // doc.text(`Opening Balance: ${openingBalance}`, 600, 50);

  doc.autoTable({
    startY: 70,
    margin: { top: 2 },
    head: [["Sr No.", "Date", "Codes", "Credit", "Debit", "Balance"]],
    body: [
      ["", "", "Opening Balance", "", "", `${openingBalance}`],
      ...tableData,
    ],
    styles: { fontSize: 12, cellPadding: 5 },
    theme: "striped", // Set the theme to striped
    alternateRowStyles: { fillColor: [240, 240, 240] }, // Light grey for alternating rows
    tableLineColor: [0, 0, 0], // Black borders
    tableLineWidth: 0.1, // Table border width
    headStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      fontStyle: "bold",
    }, // White header with black text
    didDrawPage: function (data) {
      if (data.pageCount === 1) {
        // Title and header information
        doc.setFontSize(14);
        // doc.text(`Ledger - ${ledgerType}`, 40, 30);
        doc.text(`LEDGER`, 350, 30);

        if (startDate != null && endDate != null) {
          doc.text(
            `${moment(startDate).format("DD-MM-YYYY")} to ${moment(
              endDate
            ).format("DD-MM-YYYY")}`,
            600,
            30
          );
        }

        doc.text(`M/S: ${clientName}`, 40, 30);
      }
      // Footer
      let footerStr = `Page ${data.pageCount}`;
      doc.text(
        footerStr,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    },
    // Cell styles configuration
    headStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      fontStyle: "bold",
    }, // White header with black text
    bodyStyles: { textColor: [0, 0, 0] }, // Black text for body cells
    alternateRowStyles: { fillColor: [240, 240, 240] }, // Light grey for alternating rows
    columnStyles: {
      // Apply borders to all cells
      0: { lineWidth: 0.1, lineColor: [0, 0, 0] },
      1: { lineWidth: 0.1, lineColor: [0, 0, 0] },
      2: { lineWidth: 0.1, lineColor: [0, 0, 0] },
      3: { lineWidth: 0.1, lineColor: [0, 0, 0] },
      4: { lineWidth: 0.1, lineColor: [0, 0, 0] },
      5: { lineWidth: 0.1, lineColor: [0, 0, 0] },
    },
  });

  console.log("tableData>>>>", `${tableData[tableData.length - 1][5]}`, closingBalance);

  let closingBalanceRow = [
    "",
    "",
    "",
    "",
    "",
    `Closing Balance:${tableData[tableData.length - 1][5]}`,
    // `Closing Balance:${closingBalance}`,
    // console.log(closingBalance, "colosing204")
  ];

  if (pay) {
    console.log("yaha se 207")
    // const closingBalance = tableData.reduce(
    //   (sum, row) => sum + Number(row[5]),
    //   0
    // );
    const closingBalance = tableData.length > 0 ? tableData[tableData.length - 1][5] : 0;
    closingBalanceRow = [
      "",
      "",
      "",
      "",
      "",
      `Closing Balance:${closingBalance}`,
    ];
  }

  doc.autoTable({
    startY: doc.lastAutoTable.finalY + 0,
    showHead: "never",
    margin: { top: 2 },
    head: [["Sr No.", "Date", "Codes", "Credit", "Debit", "Balance"]],
    body: [closingBalanceRow],
    styles: { fontSize: 12, cellPadding: 5, fontStyle: "bold" },
    theme: "plain",
    tableLineColor: [0, 0, 0], // Black borders
    tableLineWidth: 0.1,
    columnStyles: {
      5: { halign: "right" }, // Right align the last column (index 5)
      4: { halign: "right" }, // Right align the last column (index 5)
    },
  });

  const pdfBlob = doc.output("blob");

  // Open the PDF in a new browser tab
  const url = window.URL.createObjectURL(pdfBlob);
  window.open(url, "_blank");
  // return doc.save('ledger.pdf');
};
