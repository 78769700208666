import React, { useEffect, useRef, useState } from "react";
import GridItem from "../../components/Grid/GridItem";
import { Button, Grid, Box, makeStyles } from "@material-ui/core";
import { useReactToPrint } from "react-to-print";

const styles = {
  wrapper: {
    width: 376,
    height: 188,
  },
  barcodeWrapper: {
    marginTop: "5px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    "& p": {
      fontSize: 5,
      margin: 0,
    },
    "& svg": {
      width: "100px",
      height: 33,
      marginTop: "-7px",
    },
  },
  text: {
    fontSize: "15px!important",
    fontWeight: "400!important",
    // marginLeft: "5px!important",
  },
  senderText: {
    fontSize: "15px!important",
    // fontWeight: "300!important",
    // marginLeft: "5px!important",
  },
};

const useStyles = makeStyles(styles);

const AccountAddress = (props) => {
  const classes = useStyles();

  const componentRef = useRef();
  let { account, sender } = props.location.state;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "",
    pageStyle: ` 
    @media print {  
        @page {
            size: 333px 200px;
            margin: 0mm;
            padding: 0mm;
        } 
    }`,
  });
  console.log(account, "Account in account Address")
  return (
    <div>
      <Button color="primary" onClick={handlePrint}>
        Print Address
      </Button>
      <Box ref={componentRef} style={{ padding: 14 }}>
        <div className={classes.wrapper}>
          <div className={classes.barcodeWrapper}>
            <p className={classes.text}>To, {account.name}</p>
            <p className={classes.text}>{account.address}</p>
            <p className={classes.text}>
              {account.city}, {account.state}
            </p>
            {
              account.mobile != ''
                ?
                <p className={classes.text}>M: {account.mobile}</p>
                :
                null
            }
            <p className={classes.text}>Pincode: {account.pin}</p>
            <p className={classes.senderText}>
              From : {sender.name}, {sender.address}
            </p>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default AccountAddress;
