import React, { useEffect, useState } from "react";
import _ from "lodash";
// @material-ui/core components
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Fab,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
} from "@material-ui/core";
import moment from "moment";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
// import Table from "../../components/Table/Table.js";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import OrderView from "../Order/OrderView";
import axios from "axios";
import PrintIcon from "@mui/icons-material/Print";
import {
  BulkPrintOrdersAPI,
  DeleteOrderAPI,
  GetAllOrdersAPI,
  UpdateMultipleOrders,
  UpdateOrderAPI,
} from "../../config/api";
import { ConfirmationDialog } from "../../components/common";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import Checkbox from "@material-ui/core/Checkbox";
import { useHistory } from "react-router-dom";
import CardHeader from "../../components/Card/CardHeader";
import People from "@material-ui/icons/People";
import CustomInput from "../../components/CustomInput/CustomInput";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { OrderDetailsDialog } from "../OrderApp/OrderDetailsDialog.js";
import CompanyOrderView from "../OrderApp/CompanyOrderView.js";
import { useSelector } from "react-redux";
import FittingOrderAdd from "../Order/FittingOrderAdd.js";
import { toast } from "react-toastify";
import { readyOrderTemplate } from "../../assets/html/readyOrderTemplate.js";
import { dataURItoBlob, htmlTemplateToDataString } from "../../utils/index.js";
import { Search } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const columns = [
  { id: "party", label: "Party" },
  { id: "code", label: "Order Code" },
  { id: "orderType", label: "Order Type" },
  { id: "productNameR", label: "Product Name R" },
  { id: "productNameL", label: "Product Name L" },
  // { id: "productType", label: "Product Type" },
  {
    id: "orderAmount",
    label: "Order Amount",
    format: (value) =>
      Number(value).toLocaleString("en-IN", {
        maximumFractionDigits: 2,
        style: "currency",
        currency: "INR",
      }),
  },
  {
    id: "createdAt",
    label: "Order Date",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  },
  { id: "action", label: "Action" },
];

const columnsCompany = [
  { id: "party", label: "Party" },
  // { id: "orderType", label: "Order Type" },
  { id: "totalOrders", label: "Total Orders" },
  { id: "totalAmt", label: "Total Amount" },
  { id: "totalQty", label: "Total Quantity" },

  {
    id: "date",
    label: "Order Date",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  },
  { id: "action", label: "Action" },
];

const orderTypes = ["Fitting", "Prescription"];

export default function ReadyOrderList(props) {
  const classes = useStyles();
  const history = useHistory();

  const [orders, setOrders] = useState([]);
  const [ordersGrouped, setOrdersGrouped] = useState([]);

  const [openReadyOrderAddDialog, setOpenReadyOrderAddDialog] = useState(false);
  const [openPrescriptionOrderAddDialog, setOpenPrescriptionOrderAddDialog] =
    useState(false);
  const [openFittingOrderAddDialog, setOpenFittingOrderAddDialog] =
    useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRows1, setSelectedRows1] = useState([]);
  console.log("first", selectedRows1)

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openViewDialog, setOpenViewDialog] = useState(null);
  const [openViewCompanyOrderDialog, setOpenViewCompanyOrderDialog] =
    useState(null);

  const [openOrderTypeDialog, setOpenOrderTypeDialog] = useState(false);
  const [selectedType, setSelectedType] = useState(orderTypes[0]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(null);
  const [openOrderDetailsModal, setOpenOrderDetailsModal] = useState(null);
  const [orderAdminDetails, setOrderAdminDetails] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const [tota, setTota] = useState(0);
  const userInfo = useSelector((state) => state.userReducer.userInfo);
  const authToken = useSelector((state) => state.userReducer.authToken);

  useEffect(() => {
    _getOrders(page, rowsPerPage);
  }, [openViewCompanyOrderDialog, page, rowsPerPage]);

  async function searchData(term) {
    setSearchTerm(term);
    let res = await axios.get(
      GetAllOrdersAPI(userInfo.entity) +
        `&sortBy=createdAt:desc&orderReturnStatus=None&limit=${rowsPerPage}&skip=${
          page * rowsPerPage
        }&code=${term}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    setOrders(res.data.orders);
    setOrdersGrouped(res.data.ordersGrouped);
    setTota(res.data.total);
  }

  async function _getOrders(page, rowsPerPage) {
    try {
      let res = await axios.get(
        GetAllOrdersAPI(userInfo.entity) +
          `&sortBy=createdAt:desc&orderReturnStatus=None&limit=${rowsPerPage}&skip=${
            page * rowsPerPage
          }`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setOrders(res.data.orders);
      setOrdersGrouped(res.data.ordersGrouped);
    } catch (err) {
      // console.log("error:", err);
    }
  }

  //print ready order

  const handlePrint = async (row) => {
    let options = {
      orientation: "p",
      unit: "pt",
      format: [284, 300],
      // format: 'letter',
      putOnlyUsedFonts: true,
    };
    let htmlTemplate = readyOrderTemplate(row);
    await htmlTemplateToDataString(htmlTemplate, options, (dataString) => {
      const preBlob = dataURItoBlob(dataString);
      window.open(URL.createObjectURL(preBlob, "_blank"));
    });
  };

  const handlePrints = async (rows,data) => {
    console.log(rows,data, "<<<<props")
    let options = {
      orientation: "p",
      unit: "pt",
      format: [284, 300],
      // format: 'letter',
      putOnlyUsedFonts: true,
    };
    let htmlTemplate = readyOrderTemplate(rows);
    await htmlTemplateToDataString(htmlTemplate, options, (dataString) => {
      const preBlob = dataURItoBlob(dataString);
      window.open(URL.createObjectURL(preBlob, "_blank"));
    });
  };

  async function _deleteItem(item) {
    try {
      console.log(item, "to del")
      // return
      let res = await axios.delete(DeleteOrderAPI(item._id), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      // window.location.reload();
      _getOrders();
    } catch (e) {
      // console.log("err:", e);
    }
  }

  async function _onPrintAllOrders() {
    try {
      let res = await axios.post(
        BulkPrintOrdersAPI(),
        {
          orders: selectedRows,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      let tmp = [];
      selectedRows.map((i) => {
        let o = _.find(orders, { _id: i });
        tmp.push(o);
      });
      history.push("/admin/order-print", { orders: tmp });
    } catch (e) {
      // console.log("err:", e);
    }
  }

  async function _confirmOrder(row, data) {
    try {
      let res = await axios.patch(
        UpdateOrderAPI(row.code),
        {
          orderAdminStatus: "Confirmed",
          orderAdminDetails: [...row.orderAdminDetails, data],
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      _getOrders();
    } catch (err) {
      // console.log(err);
    }
  }

  async function _confirmMultipleOrders(selectedRows1, data) {
    try {
      // Construct the API URL
      const apiURL = UpdateMultipleOrders(); // Get the API URL
      console.log("API URL:", apiURL, selectedRows1); // Log it to verify if it's correct
      // return

      // Create an array of updates for each selected order
      const updates = selectedRows1.map((row) =>
        //   {
        //   console.log("first", row);
        // });
        ({
          code: row.code,
          orderAdminStatus: "Confirmed",
          orderAdminDetails: [...row.orderAdminDetails, data],
        })
      );

      // Make the API request to update multiple orders
      let res = await axios.patch(
        apiURL, // API endpoint for bulk updating orders
        { selectedRows: updates }, // Send the selectedRows in req.body
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include authorization header
          },
        }
      );

      console.log(res);
      _getOrders(); // Refresh the orders list after the update
      setSelectedRows1([])
      if (res.status === 200) {
        toast.success("Records Updated."); // Success message
      }
    } catch (err) {
      console.error("Error occurred:", err);
      // Optionally show a notification or toast
      toast.error("Error updating records."); // Error message
    }
  }

  async function _rejectOrder(row, data) {
    try {
      let res = await axios.patch(
        UpdateOrderAPI(row.code),
        {
          orderAdminStatus: "Rejected",
          orderAdminDetails: [...row.orderAdminDetails, data],
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      _getOrders();
    } catch (err) {
      // console.log(err);
    }
  }

  const handleOpenConfirmationModal = (object) => {
    setOpenConfirmationModal(object);
  };
  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(null);
  };

  const handleOpenOrderDetailsModal = (object) => {
    setOpenOrderDetailsModal(object);
  };
  const handleCloseOrderDetailsModal = () => {
    setOpenOrderDetailsModal(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function closeReadyOrderAddDialog() {
    setOpenReadyOrderAddDialog(false);
  }

  function closeFittingOrderAddDialog() {
    setOpenFittingOrderAddDialog(false);
  }

  function closePrescriptionOrderAddDialog() {
    setOpenPrescriptionOrderAddDialog(false);
  }

  function closeViewDialog() {
    setOpenViewDialog(null);
  }

  function closeViewCompanyOrderDialog() {
    setOpenViewCompanyOrderDialog(null);
  }

  function handleSearch() {
    searchData(search);
  }

  function _renderTable(orderType, data) {
    console.log(data, "<<<<data421", orderType)
    // if (searchTerm !== "") {
    //   data = _.filter(data, (i) => {
    //     return (
    //       i.code && i.code.toLowerCase().includes(searchTerm.toLowerCase())
    //     );
    //   });
    // }
    // if (orderType==='Prescription'){
    //     data = _.orderBy(data, ['createdAt'], ['desc']);
    // }
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {/* {orderType === "Prescription" ? (
            <Button
              color="primary"
              onClick={() =>
                handleOpenConfirmationModal({
                  msg: "Do you want to print all records",
                  onPress: () => _onPrintAllOrders(),
                })
              }
            >
              Print All Records
            </Button>
          ) : (
            <div />
          )} */}
          <div>
            <CustomInput
              id="search"
              formControlProps={{
                fullWidth: false,
              }}
              inputProps={{
                style: {
                  color: "primary",
                  marginTop: 0,
                },
                placeholder: "Search",
                value: search,
                // onChange: (e) => searchData(e.target.value),
                // onSubmit: (e) => searchData(e.target.value),
                onChange: (e) => {
                  setSearch(e.target.value);
                  if (e.target.value === "" || e.target.value === null) {
                    _getOrders(page, rowsPerPage);
                  }
                },
                // onChange: (e) => handleInputChange(e),
                onKeyDown: (e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    handleSearch();
                  }
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color={"primary"}
                      onClick={(e) => {
                        // setSearchTerm(e.target.value),
                        // handleKeyPress(e)
                        // handleKeyPress()
                        handleSearch();
                      }}
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <IconButton
              aria-label="add"
              onClick={() => setOpenFittingOrderAddDialog(true)}
            >
              <AddIcon />
            </IconButton>
          </div>
        </Box>

        <Box>
        <div>
    {/* Conditionally render the Confirm button when selectedRows1 is not empty */}
    {selectedRows1.length > 0 && (
      <>
        <Button
          color="Primary"
          onClick={() =>
            handleOpenOrderDetailsModal({
              title: "Do you want to Accept Multiple Orders?",
              onPress: (data) =>
                _confirmMultipleOrders(selectedRows1, data),
            })
          }
        >
          Confirm Selected Orders
        </Button>

        {/* Add the new Print Selected Orders button */}
        <Button
          color="primary"
          onClick={() =>
            handleOpenOrderDetailsModal({
              title: "Do you want to Print Multiple Orders?",
              onPress: (data) =>
                handlePrints(selectedRows1,data),
            })
          }
        >
          Print Selected Orders
        </Button>
      </>
    )}

    {/* Display selected rows for debugging */}
    <div>
      <h4>Selected Rows: {selectedRows1.length}</h4>
    </div>
  </div>
        </Box>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {orderType === "Prescription" ? (
                <Checkbox
                  style={{
                    color: "#512da8",
                  }}
                  onChange={(e) => {
                    let c = [];
                    let d = [];
                    if (e.target.checked) {
                      orders.map((i) => {
                        c.push(i._id);
                      });
                      data.map((i) => {
                        d.push(i);
                      });
                    }
                    setSelectedRows(c);
                    setSelectedRows1(d);
                  }}
                  checked={
                    selectedRows.length > 0 &&
                    orders.length === selectedRows.length
                  }
                  inputProps={{ "aria-labelledby": -1 }}
                />
              ) : null}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {orderType === "Prescription" ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          style={{
                            color: "#512da8",
                          }}
                          onChange={() => {
                            let copy = _.clone(selectedRows);
                            if (_.includes(copy, row._id)) {
                              let index = _.indexOf(copy, row._id);
                              copy.splice(index, 1);
                            } else {
                              copy.push(row._id);
                            }
                            setSelectedRows(copy);
                            let rows = _.clone(selectedRows1);

// Use _.findIndex to find the correct row by _id
let index = _.findIndex(rows, r => r._id === row._id);

if (index !== -1) {
  console.log("itincludes", rows, "removing");
  rows.splice(index, 1);  // Remove the row by index
} else {
  console.log("pushing");
  rows.push(row);  // Push the entire row object
}

setSelectedRows1(rows);
                          }}
                          checked={_.includes(selectedRows, row._id)}
                          inputProps={{ "aria-labelledby": row._id }}
                        />
                      </TableCell>
                    ) : null}
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id === "product") {
                        if (row.orderType === "Fitting") {
                          value =
                            row.orderDetail && row.orderDetail.productName
                              ? row.orderDetail.productName
                              : "";
                        } else if (row.orderType === "Ready") {
                          value =
                            row.orderDetail && row.orderDetail.product
                              ? row.orderDetail.product.name
                              : "";
                        } else {
                          value =
                            row.orderDetail && row.orderDetail.product
                              ? row.orderDetail.product.productName
                              : "";
                        }
                      }
                      if(column.id==="productNameL"){
                        value = row.orderDetail.side === "L" ? row.orderDetail.productName : row.orderDetail.lLens
                      }
                      if(column.id==="productNameR"){
                        value = row.orderDetail.side === "R" ? row.orderDetail.productName : row.orderDetail.rLens
                      }
                      if (column.id === "action") {
                        return (
                          <TableCell align={"center"}>
                            <Box display={"flex"} justifyContent={"center"}>
                              <IconButton
                                aria-label="view"
                                onClick={() =>
                                  setOpenViewDialog({
                                    open: true,
                                    item: row,
                                  })
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton
                                aria-label="view"
                                onClick={() => handlePrint(row)}
                              >
                                <PrintIcon />
                              </IconButton>
                              {row.orderAdminStatus === "Pending" && (
                                <React.Fragment>
                                  <IconButton
                                    aria-label="confirm"
                                    onClick={() =>
                                      handleOpenOrderDetailsModal({
                                        title: "Do you want to Accept Order",
                                        onPress: (data) =>
                                          _confirmOrder(row, data),
                                      })
                                    }
                                    // onClick={(data) => _confirmOrder(row, data)}
                                  >
                                    <CheckCircleIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="reject"
                                    onClick={() =>
                                      handleOpenOrderDetailsModal({
                                        title: "Do you want to Reject Order",
                                        onPress: (data) =>
                                          _rejectOrder(row, data),
                                      })
                                    }
                                    // onClick={(data) => _rejectOrder(row.code,data)}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </React.Fragment>
                              )}
                              <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  handleOpenConfirmationModal({
                                    msg: "Do you want to delete this record?",
                                    title: "Delete Record",
                                    onPress: () => _deleteItem(row),
                                  })
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        );
                      }

                      if (column.id === "party") {
                        return (
                          <TableCell key={column.id} align={"center"}>
                            {value.accountName}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.id} align={"center"}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </GridItem>
    );
  }

  function _renderTableCompany(orderType, data) {
    if (searchTerm !== "") {
      data = _.filter(data, (i) => {
        return (
          i.code && i.code.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    }
    if (orderType === "Prescription") {
      data = _.orderBy(data, ["createdAt"], ["desc"]);
    }
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {/* {orderType === "Prescription" ? (
            <Button
              color="primary"
              onClick={() =>
                handleOpenConfirmationModal({
                  msg: "Do you want to print all records",
                  onPress: () => _onPrintAllOrders(),
                })
              }
            >
              Print All Records
            </Button>
          ) : (
            <div />
          )} */}
          <div>
            <CustomInput
              id="search"
              formControlProps={{
                fullWidth: false,
              }}
              inputProps={{
                style: {
                  color: "primary",
                  marginTop: 0,
                },
                placeholder: "Search",
                value: searchTerm,
                onChange: (e) => setSearchTerm(e.target.value),
                endAdornment: (
                  <InputAdornment position="end">
                    <People />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Box>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {orderType === "Prescription" ? (
                <Checkbox
                  style={{
                    color: "#512da8",
                  }}
                  onChange={(e) => {
                    let c = [];
                    if (e.target.checked) {
                      orders.map((i) => {
                        c.push(i._id);
                      });
                    }
                    setSelectedRows(c);
                  }}
                  checked={
                    selectedRows.length > 0 &&
                    orders.length === selectedRows.length
                  }
                  inputProps={{ "aria-labelledby": -1 }}
                />
              ) : null}
              {columnsCompany.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {orderType === "Prescription" ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          style={{
                            color: "#512da8",
                          }}
                          onChange={() => {
                            let copy = _.clone(selectedRows);
                            if (_.includes(copy, row._id)) {
                              let index = _.indexOf(copy, row._id);
                              copy.splice(index, 1);
                            } else {
                              copy.push(row._id);
                            }
                            setSelectedRows(copy);
                          }}
                          checked={_.includes(selectedRows, row._id)}
                          inputProps={{ "aria-labelledby": row._id }}
                        />
                      </TableCell>
                    ) : null}
                    {columnsCompany.map((column) => {
                      let value = row[column.id];

                      if (column.id === "action") {
                        return (
                          <TableCell align={"center"}>
                            <Box display={"flex"} justifyContent={"center"}>
                              <IconButton
                                aria-label="view"
                                onClick={() =>
                                  setOpenViewCompanyOrderDialog({
                                    open: true,
                                    item: row,
                                  })
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              {row.orderAdminStatus === "Pending" && (
                                <React.Fragment>
                                  <IconButton
                                    aria-label="confirm"
                                    onClick={() =>
                                      handleOpenOrderDetailsModal({
                                        title: "Do you want to Accept Order",
                                        onPress: (data) =>
                                          _confirmOrder(row, data),
                                      })
                                    }
                                    // onClick={(data) => _confirmOrder(row, data)}
                                  >
                                    <CheckCircleIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="reject"
                                    onClick={() => ({
                                      title: "Do you want to Reject Order",
                                      onPress: (data) =>
                                        _rejectOrder(row, data),
                                    })}
                                    // onClick={(data) => _rejectOrder(row.code,data)}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </React.Fragment>
                              )}
                              {/* <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  handleOpenConfirmationModal({
                                    msg: "Do you want to delete this record?",
                                    title: "Delete Record",
                                    onPress: () => _deleteItem(row),
                                  })
                                }
                              >
                                <DeleteIcon />
                              </IconButton> */}
                            </Box>
                          </TableCell>
                        );
                      }

                      if (column.id === "totalOrders") {
                        return (
                          <TableCell key={column.id} align={"center"}>
                            {row.orders.length}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell key={column.id} align={"center"}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </GridItem>
    );
  }

  function _renderTableOrderInstance(orderType, data) {
    if (searchTerm !== "") {
      data = _.filter(data, (i) => {
        return (
          i.code && i.code.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    }
    if (orderType === "Prescription") {
      data = _.orderBy(data, ["createdAt"], ["desc"]);
    }
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {/* {orderType === "Prescription" ? (
            <Button
              color="primary"
              onClick={() =>
                handleOpenConfirmationModal({
                  msg: "Do you want to print all records",
                  onPress: () => _onPrintAllOrders(),
                })
              }
            >
              Print All Records
            </Button>
          ) : (
            <div />
          )} */}
          <div>
            <CustomInput
              id="search"
              formControlProps={{
                fullWidth: false,
              }}
              inputProps={{
                style: {
                  color: "primary",
                  marginTop: 0,
                },
                placeholder: "Search",
                value: searchTerm,
                onChange: (e) => setSearchTerm(e.target.value),
                endAdornment: (
                  <InputAdornment position="end">
                    <People />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Box>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {orderType === "Prescription" ? (
                <Checkbox
                  style={{
                    color: "#512da8",
                  }}
                  onChange={(e) => {
                    let c = [];
                    if (e.target.checked) {
                      orders.map((i) => {
                        c.push(i._id);
                      });
                    }
                    setSelectedRows(c);
                  }}
                  checked={
                    selectedRows.length > 0 &&
                    orders.length === selectedRows.length
                  }
                  inputProps={{ "aria-labelledby": -1 }}
                />
              ) : null}
              {columnsCompany.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {orderType === "Prescription" ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          style={{
                            color: "#512da8",
                          }}
                          onChange={() => {
                            let copy = _.clone(selectedRows);
                            if (_.includes(copy, row._id)) {
                              let index = _.indexOf(copy, row._id);
                              copy.splice(index, 1);
                            } else {
                              copy.push(row._id);
                            }
                            setSelectedRows(copy);
                          }}
                          checked={_.includes(selectedRows, row._id)}
                          inputProps={{ "aria-labelledby": row._id }}
                        />
                      </TableCell>
                    ) : null}
                    {columnsCompany.map((column) => {
                      let value = row[column.id];

                      if (column.id === "action") {
                        return (
                          <TableCell align={"center"}>
                            <Box display={"flex"} justifyContent={"center"}>
                              <IconButton
                                aria-label="view"
                                onClick={() =>
                                  setOpenViewCompanyOrderDialog({
                                    open: true,
                                    item: row,
                                  })
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              {row.orderAdminStatus === "Pending" && (
                                <React.Fragment>
                                  <IconButton
                                    aria-label="confirm"
                                    onClick={() =>
                                      handleOpenOrderDetailsModal({
                                        title: "Do you want to Accept Order",
                                        onPress: (data) =>
                                          _confirmOrder(row, data),
                                      })
                                    }
                                    // onClick={(data) => _confirmOrder(row, data)}
                                  >
                                    <CheckCircleIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="reject"
                                    onClick={() => ({
                                      title: "Do you want to Reject Order",
                                      onPress: (data) =>
                                        _rejectOrder(row, data),
                                    })}
                                    // onClick={(data) => _rejectOrder(row.code,data)}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </React.Fragment>
                              )}
                              {/* <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  handleOpenConfirmationModal({
                                    msg: "Do you want to delete this record?",
                                    title: "Delete Record",
                                    onPress: () => _deleteItem(row),
                                  })
                                }
                              >
                                <DeleteIcon />
                              </IconButton> */}
                            </Box>
                          </TableCell>
                        );
                      }

                      if (column.id === "party") {
                        return (
                          <TableCell key={column.id} align={"center"}>
                            {value.accountName}
                          </TableCell>
                        );
                      }

                      if (column.id === "totalOrders") {
                        return (
                          <TableCell key={column.id} align={"center"}>
                            {row.orders.length}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.id} align={"center"}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </GridItem>
    );
  }

  //   let pendingFittingOrders = _.filter(orders, {
  //     orderType: "Fitting",
  //     orderStatus: "Pending",
  //   });
  //   let completedFittingOrders = _.filter(orders, {
  //     orderType: "Fitting",
  //     orderStatus: "Completed",
  //   });

  // update confirmed to pending
  let companyPrescriptionOrders = _.chain(orders)
    .filter((order) => order.orderAdminStatus === "Pending")
    .groupBy("party.accountCode")
    .map((value, key) => ({ party: key, orders: value }))
    .value();

  let pendingPrescriptionOrders = _.filter(orders, {
    orderType: "Fitting",
    // orderStatus: "Pending",
    orderAdminStatus: "Pending",
    isOrderFromApp: false,
  });
  let completedFittingOrders = _.filter(orders, {
    orderType: "Fitting",
    // orderStatus: "Completed",
    isOrderFromApp: false,
    orderAdminStatus: "Confirmed",
  });
  let pendingFittingOrdersApp = _.filter(orders, {
    orderType: "Fitting",
    orderAdminStatus: "Pending",
    isOrderFromApp: true,
  });
  let completedFittingOrdersApp = _.filter(orders, {
    orderType: "Fitting",
    orderAdminStatus: "Confirmed",
    isOrderFromApp: true,
  });

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          title=""
          headerColor="primary"
          tabs={[
            {
              tabName: "Pending Web",
              tabIcon: Code,
              tabContent: _renderTable(
                "Prescription",
                pendingPrescriptionOrders
              ),
            },
            {
              tabName: "Confirmed Web",
              tabIcon: Cloud,
              tabContent: _renderTable("Fitting", completedFittingOrders),
            },
            {
              tabName: "Pending App",
              tabIcon: Code,
              tabContent: _renderTable("Prescription", pendingFittingOrdersApp),
            },
            {
              tabName: "Confirmed App",
              tabIcon: Cloud,
              tabContent: _renderTable(
                "Prescription",
                completedFittingOrdersApp
              ),
            },
          ]}
        />
      </GridItem>
      <FittingOrderAdd
        fetchData={_getOrders}
        open={openFittingOrderAddDialog}
        close={closeFittingOrderAddDialog}
      />
      {openViewDialog ? (
        <OrderView
          open={openViewDialog.open}
          close={closeViewDialog}
          item={openViewDialog.item}
        />
      ) : null}
      {openViewCompanyOrderDialog ? (
        <CompanyOrderView
          open={openViewCompanyOrderDialog.open}
          close={closeViewCompanyOrderDialog}
          item={openViewCompanyOrderDialog.item}
          orderType={"Prescription"}
        />
      ) : null}
      {openOrderDetailsModal ? (
        <OrderDetailsDialog
          open={openOrderDetailsModal}
          onClose={handleCloseOrderDetailsModal}
          onConfirm={openOrderDetailsModal.onPress}
          title={openOrderDetailsModal.title}
          msg={openOrderDetailsModal.msg}
          setOrderAdminDetails={setOrderAdminDetails}
          orderAdminDetails={orderAdminDetails}
        />
      ) : null}
      {openConfirmationModal ? (
        <ConfirmationDialog
          open={openConfirmationModal}
          onClose={handleCloseConfirmationModal}
          onConfirm={openConfirmationModal.onPress}
          title={openConfirmationModal.title}
          msg={openConfirmationModal.msg}
        />
      ) : null}
    </GridContainer>
  );
}
