import React, { useEffect } from "react";
// @material-ui/core components
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  makeStyles,
  Slide,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import { baseUrl } from "../../config/api.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};
const columns = [
  { id: "product", label: "Product" },
  { id: "discountPercent", label: "Discount Percent" },
  { id: "discountAmount", label: "Discount Amount" },
];
const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function OrderView(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { open, close, item } = props;
  console.log(item, "this is item");
  console.log(item, "My item")
  let {
    orderType,
    party,
    orderNo,
    price,
    orderAmount,
    quantity,
    code,
    orderDetail,
    orderReturnStatus,
    orderAdminStatus,
    orderAdminDetails,
    orderReturnCustomerDetails,
    orderReturnAdminDetails,
    tintImage,
    frameImage,
    imageName,
    customerName,
    orderNotes
  } = item;

  const {
    rLens,
    lLens,
    rRate,
    lRate,
    fitter,
    fitting,
    amount,
    lab,
    given,
    received,
    partyRef,
    productNameR,
    productNameL,
    productType,
    productName,
    cg,
    dia,
    prism,
    coat,
    color,
    type,
    brand,
    design,
    tint,
    rSph,
    lSph,
    lCyl,
    rCyl,
    rAxis,
    lAxis,
    rAddition,
    lAddition,
    ed,
    pd,
    rBlank,
    lBlank,
    labName,
    labRecvd,
    labAmount,
    arcName,
    arcType,
    arcAmount,
    fittingName,
    fittingType,
    fittingGiven,
    fittingRecvd,
    fittingAmount,
    fittingHeight,
    index,
    urgent,
    frameSection,
    oTaken,
    delivered,
    tintAmount,
    others,
    note,
    challanCreated,
    isEmailSent,
    product,
  } = orderDetail;



  return (
    <Dialog
      // fullScreen={false}
      // fullWidth
      fullScreen
      maxWidth={"lg"}
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>Order Details</h4>
                <Button style={{ color: "white" }} onClick={close}>
                  Close
                </Button>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Order Type"
                    id="orderType"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: orderType,
                      disabled: true,
                    }}
                  />
                </GridItem>
                {orderType === "Fitting" && (
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="R Lens"
                      id="rLens"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: rLens,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}
                {orderType === "Fitting" && (
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="L Lens"
                      id="lLens"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: lLens,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}
                {orderType === "Fitting" && (
                  <GridItem md={6} />
                )}
                {orderType === "Fitting" && (
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      labelText="R Rate"
                      id="rRate"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: rRate,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}
                {orderType === "Fitting" && (
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      labelText="L Rate"
                      id="lrate"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: lRate,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}
                {orderType === "Fitting" && (
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Fitter"
                      id="fitter"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: fitter,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}
                {orderType === "Fitting" && (
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Fitting"
                      id="fitting"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: fitting,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}

                <GridItem xs={12} sm={12} md={1}>
                  <CustomInput
                    labelText="Order No"
                    id="orderNo"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: orderNo,
                      disabled: true,
                    }}
                  />
                </GridItem>
                {orderType === "Fitting" && (
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Given"
                      id="given"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: moment(given).format("DD-MM-YY "),
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}
                {orderType === "Fitting" && (
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText={"Received"}
                      id="Received"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: moment(received).format("DD-MM-YY"),
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}

                {orderType === "Prescription" && (
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Lab"
                      id="lab"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: lab,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}
                {orderType === "Prescription" && (
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="productType"
                      id="productType"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: productType,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}
                {orderType === "Prescription" && (
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="productName"
                      id="productName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: productName,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}
              

                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Party"
                    id="party"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: party.accountName,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Party Reference"
                    id="partyRef"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: partyRef,
                      disabled: true,
                    }}
                  />
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="customerName"
                    id="customerName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: customerName ? customerName : orderDetail?.clientName ? orderDetail.clientName : orderDetail.customerName,
                      disabled: true,
                    }}
                  />
                </GridItem> */}


                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="productNameR"
                    id="productNameR"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: productNameR,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="productNameL"
                    id="productNameL"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: productNameL,
                      disabled: true,
                    }}
                  />
                </GridItem>
                {orderType === "Prescription" && (
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      labelText="Coat"
                      id="coat"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: coat,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}

                {orderType === "Prescription" && (
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      labelText={"Index"}
                      id="index"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: index,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}
                <GridItem xs={12} sm={12} md={1}>
                  <CustomInput
                    labelText="Dia/Ed"
                    id="dia"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: dia,
                      disabled: true,
                    }}
                  />
                </GridItem>








                <GridItem xs={12} sm={12} md={12}></GridItem>

                {/* {orderType === "Prescription" && ( */}
                <GridItem xs={12} sm={12} md={1}></GridItem>
                {/* )} */}
                <GridItem xs={12} sm={12} md={1}>
                  <p>Sph</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                  <p>Cyl</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                  <p>Axis</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                  <p>Add</p>
                </GridItem>
                <GridItem xs={12} sm={12} ></GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={1}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <p>Right</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                  <CustomInput
                    id="rSph"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: rSph,
                      disabled: true,
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={1}>
                  <CustomInput
                    id="rCyl"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: rCyl,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                  <CustomInput
                    id="rAxis"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: rAxis,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                  <CustomInput
                    id="rAddition"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: rAddition,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                  <CustomInput
                    labelText={"Quantity"}
                    id="quantity"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: quantity,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}></GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={1}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <p>Left</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                  <CustomInput
                    id="lSph"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: lSph,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                  <CustomInput
                    id="lCyl"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: lCyl,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                  <CustomInput
                    id="lAxis"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: lAxis,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                  <CustomInput
                    id="lAddition"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: lAddition,
                      disabled: true,
                    }}
                  />
                </GridItem>

{
  product?.side 
  ?
<GridItem xs={12} sm={12} md={1}>
                  <CustomInput
                    labelText={"Side"}
                    id="side"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: product.side,
                      disabled: true,
                    }}
                  />
                </GridItem>
                :null
}
                


                <GridItem xs={12}>
                  <Box style={{ display: "flex" }}>
                    {tintImage && (
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: 20,
                        }}
                      >
                        <Typography variant="body1">Tint Image</Typography>
                        <img
                          src={`${baseUrl}/uploads/${tintImage}`}
                          style={{ height: 200, width: 200 }}
                        />
                      </Box>
                    )}
                    {frameImage && (
                      <Box style={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="body1">Frame Image</Typography>
                        <img
                          src={`${baseUrl}/uploads/${frameImage}`}
                          style={{ height: 200, width: 200 }}
                        />
                      </Box>
                    )}
                    {imageName && (
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="body1">Prescription Order Image</Typography>
                        <img
                          src={`${baseUrl}/uploads/${imageName}`}
                          style={{ height: 200, width: 200 }}
                        />
                      </Box>
                      )}
                  </Box>
                </GridItem>
                {orderType === "Fitting" && (
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      labelText={"Rate"}
                      id="amount"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: orderAmount,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}
                {orderType === "Prescription" && (
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      labelText={"Rate"}
                      id="amount"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: orderAmount,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}


                {orderType === "Fitting" && (
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      labelText="Cr/Gl/P"
                      id="cg"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: cg,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}

                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Order Note"
                    id="orderNotes"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: orderNotes,
                      disabled: true,
                    }}
                  />
                </GridItem>

                {orderType === "Prescription" && (
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      labelText="prism"
                      id="prism"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: prism,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}

                {orderType === "Prescription" && (
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      labelText="ed"
                      id="ed"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: ed,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}
                {orderType === "Prescription" && (
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      labelText="pd"
                      id="pd"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: pd,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}

                {orderType === "Prescription" && (
                  <GridItem
                    xs={12}
                    sm={12}
                    md={1}
                    style={{ display: "flex", alignItems: "flex-end" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tint}
                          disabled
                          name="tint"
                          color={"primary"}
                        />
                      }
                      label="Tint"
                    />
                  </GridItem>
                )}
                {orderType === "Prescription" && (
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      labelText={"Fitting Height"}
                      id="fittingHeight"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: fittingHeight,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}
                <GridItem md={6} />
                {orderType === "Prescription" && (
                  <>
                    <GridItem xs={4} sm={4} md={1}>
                      <CustomInput
                        labelText={"Quantity"}
                        id="quantity"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: orderDetail?.qtyType ? quantity + ' - ' + orderDetail?.qtyType : quantity,
                          disabled: true,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={1}>
                      <CustomInput
                        labelText={"Order Amount"}
                        id="orderAmount"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: price * quantity,
                          disabled: true,
                        }}
                      />
                    </GridItem>
                  </>
                )}
                {orderType === "Prescription" && (
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText={"Notes"}
                      id="note"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        rows: 1,
                        multiline: true,
                        value: orderDetail?.note,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}
                {/* {orderType === "Prescription" && (
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      labelText={"Email Sent"}
                      id="isEmailSent"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: isEmailSent.toString(),
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )} */}

                {orderType === "Prescription" && (
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText={"Frame Section"}
                      id="frameSection"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: frameSection,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}



                {orderAdminStatus !== "Pending" && (
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={"Order Admin Details"}
                      id="note"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        rows: 1,
                        multiline: true,
                        value: orderAdminDetails,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}

                {orderReturnStatus !== "None" && (
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={"Order Return Customer Details"}
                      id="note"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        rows: 1,
                        multiline: true,
                        value: orderReturnCustomerDetails,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}

                {orderReturnStatus !== "Pending" &&
                  orderAdminStatus !== "Pending" && (
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText={"Order Return Admin Details"}
                        id="note"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          rows: 1,
                          multiline: true,
                          value: orderReturnAdminDetails,
                          disabled: true,
                        }}
                      />
                    </GridItem>
                  )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
