import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
// core components
// import Table from "../../components/Table/Table.js";
import AddIcon from "@material-ui/icons/Add";
import PouchDB from "pouchdb";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ProductMasterAdd from "./ProductMasterAdd";
import ReactExport from "react-data-export";
import { ImportExcel } from "./ImportExcel";
import { CloudDownload, CloudUpload } from "@material-ui/icons";
import ProductMasterView from "./ProductMasterView";
import axios from "axios";
import {
  DeleteAllProductMasterAPI,
  DeleteAllReadyMasterAPI,
  DeleteByLabProductMasterAPI,
  DeleteProductMasterAPI,
  GetAllProductMastersAPI,
} from "../../config/api";
import { ConfirmationDialog } from "../../components/common";
import ProductMasterEdit from "./ProductMasterEdit";
import Checkbox from "@material-ui/core/Checkbox";
import People from "@material-ui/icons/People";
import CustomInput from "../../components/CustomInput/CustomInput";
// @material-ui/core components
import { useSelector } from "react-redux";
import { toast } from "react-toastify"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(-24),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const columns = [
  { id: "lab", label: "Lab" },
  { id: "productType", label: "Type" },
  { id: "productName", label: "Product Name" },
  { id: "dia", label: "Dia" },
  { id: "index", label: "Index" },
  { id: "coating", label: "Coating" },
  { id: "fittingHeight", label: "Fitting Height" },
  { id: "powerRange", label: "Power Range" },
  { id: "price", label: "Price" },
  { id: "action", label: "Action" },
];

let pageTypes = ["Normal", "Wide", "Normal * 2", "Wide * 2"];

export default function ProductMasterList(props) {
  const history = useHistory();
  const classes = useStyles();
  let authToken = useSelector((state) => state.userReducer.authToken);
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  const [products, setProducts] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openViewDialog, setOpenViewDialog] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openPageTypeDialog, setOpenPageTypeDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [labNames, setLabNames] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedLab, setSelectedLab] = useState("");

  useEffect(() => {
    _getProducts();
  }, []);

  async function _getProducts() {
    try {
      let res = await axios.get(GetAllProductMastersAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setProducts(res.data.productMasters);
      // Extract unique lab names
      const uniqueLabs = [
        ...new Set(res.data.productMasters.map((product) => product.lab)),
      ];
      
      setLabNames(uniqueLabs);
      console.log(res.data.productMasters, "my produts")
    } catch (err) {
      // console.log(err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _deleteItem(item) {
    try {
      let res = await axios.delete(DeleteProductMasterAPI(item._id), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      toast.success('Successfully Deleted!', {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      _getProducts();
      // window.location.reload();
    } catch (e) {
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _deleteAllRecords() {
    try {
      if (_.isEmpty(selectedRows)) {
        return;
      }
      let res = await axios.post(
        DeleteAllProductMasterAPI(userInfo.entity),
        {
          data: selectedRows,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      window.location.reload();
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function getProdIds() {
  return products.map((p) => p._id); // Corrected map usage
}

async function _deleteAllRecordsByLab() {
  try {
    let res = await axios.post(
  DeleteByLabProductMasterAPI(), // No need to pass entity in URL
  {
    entity: userInfo.entity,
    lab: selectedLab, 
  },
  {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }
);

    window.location.reload();
  } catch (e) {
    console.error("Error:", e);
    // Uncomment if you want to show an error toast
    /*
    toast.error("Something went wrong, Please Contact Admin", {
      position: "top-center",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    */
  }
}


  const handleOpenConfirmationModal = (object) => {
    setOpenConfirmationModal(object);
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function closeAddDialog() {
    setOpenAddDialog(false);
  }

  function closeViewDialog() {
    setOpenViewDialog(null);
  }

  function closeEditDialog() {
    setOpenEditDialog(null);
  }

  let data = products;
  if (searchTerm !== "") {
    // console.log(data, "My data")
    data = _.filter(data, (i) => {
      return (
        i.productName &&
        i.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        i.lab &&
        i.lab.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }

  let excelData = [];
  data.map((i) => {
    let d = [];
    d.push({ value: i.lab });
    d.push({ value: i.productType });
    d.push({ value: i.productName });
    d.push({ value: i.dia });
    d.push({ value: i.index });
    d.push({ value: i.coating });
    d.push({ value: i.fittingHeight });
    d.push({ value: i.powerRange });
    d.push({ value: i.price });
    d.push({ value: i?.hsnCode });
    d.push({ value: i?.taxPercent });
    excelData.push(d);
  });
  const multiDataSet = [
    {
      columns: [
        { title: "lab" },
        { title: "productType" },
        { title: "productName" },
        { title: "dia" },
        { title: "index" },
        { title: "coating" },
        { title: "fittingHeight" },
        { title: "powerRange" },
        { title: "price" },
        { title: "hsnCode" },
        { title: "taxPercent" },
      ],
      data: excelData,
    },
  ];

  let emptyData = [];
  let d = [];
  for (let i = 0; i < multiDataSet[0].columns.length; i++) {
    d.push({ value: "" });
  }
  emptyData.push(d);
  const importTemplateDataSet = [
    {
      columns: [
        { title: "lab" },
        { title: "productType" },
        { title: "productName" },
        { title: "dia" },
        { title: "index" },
        { title: "coating" },
        { title: "fittingHeight" },
        { title: "powerRange" },
        { title: "price" },
        { title: "hsnCode" },
        { title: "taxPercent" },
      ],
      data: emptyData,
    },
  ];

  const PageTypeDialog = () => {
    return (
      <Dialog
        onClose={() => setOpenPageTypeDialog(false)}
        aria-labelledby="simple-dialog-title"
        open={openPageTypeDialog}
      >
        <DialogTitle id="simple-dialog-title">Select Page Type</DialogTitle>
        <List>
          {pageTypes.map((item) => (
            <ListItem
              button
              onClick={() => {
                setOpenPageTypeDialog(false);
                let tmp = [];
                selectedRows.map((o) => {
                  let obj = _.find(data, { _id: o });
                  tmp.push(obj);
                });
                history.push("/admin/product-barcodes", {
                  products: tmp,
                  pageType: item,
                });
              }}
              key={item}
            >
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  };

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Button
          color="primary"
          onClick={() =>
            handleOpenConfirmationModal({
              msg: "Do you want to delete all records",
              onPress: () => _deleteAllRecords(),
            })
          }
        >
          Delete All Records
        </Button>

        <Button
          color="primary"
          onClick={() => setOpen(true)}
        >
          Delete Records By LAB
        </Button>

        <Box justifyContent={"center"} alignItems={"center"}>
          <Button
            color="primary"
            onClick={() =>
              handleOpenConfirmationModal({
                title: "Print barcode",
                msg: "Do you want to print barcodes?",
                onPress: () => setOpenPageTypeDialog(true),
              })
            }
          >
            Print Barcodes
          </Button>
          <ExcelFile
            element={
              <Tooltip title={"Export to excel"}>
                <IconButton color={"primary"}>
                  <CloudDownload />
                </IconButton>
              </Tooltip>
            }
          >
            <ExcelSheet dataSet={multiDataSet} name="Booking" />
          </ExcelFile>
          <ExcelFile
            element={
              <Tooltip title={"Import Template"}>
                <IconButton color={"primary"}>
                  <CloudUpload />
                </IconButton>
              </Tooltip>
            }
          >
            <ExcelSheet
              dataSet={importTemplateDataSet}
              name="Import Template"
            />
          </ExcelFile>
          <Tooltip title={"Import Excel to database"}>
            <IconButton
              color={"primary"}
              onClick={() => setOpenImportDialog(true)}
            >
              <CloudUpload />
            </IconButton>
          </Tooltip>
          <CustomInput
            id="search"
            formControlProps={{
              fullWidth: false,
            }}
            inputProps={{
              style: {
                color: "primary",
                marginTop: 0,
              },
              placeholder: "Search",
              value: searchTerm,
              onChange: (e) => setSearchTerm(e.target.value),
              endAdornment: (
                <InputAdornment position="end">
                  <People />
                </InputAdornment>
              ),
            }}
          />
          <IconButton aria-label="add" onClick={() => setOpenAddDialog(true)}>
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <Checkbox
              style={{
                color: "#512da8",
              }}
              onChange={(e) => {
                let c = [];
                if (e.target.checked) {
                  data.map((i) => {
                    c.push(i._id);
                  });
                }
                setSelectedRows(c);
              }}
              checked={
                selectedRows.length > 0 && data.length === selectedRows.length
              }
              inputProps={{ "aria-labelledby": -1 }}
            />
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={"center"}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      style={{
                        color: "#512da8",
                      }}
                      onChange={() => {
                        let copy = _.clone(selectedRows);
                        if (_.includes(copy, row._id)) {
                          let index = _.indexOf(copy, row._id);
                          copy.splice(index, 1);
                        } else {
                          copy.push(row._id);
                        }
                        setSelectedRows(copy);
                      }}
                      checked={_.includes(selectedRows, row._id)}
                      inputProps={{ "aria-labelledby": row._id }}
                    />
                  </TableCell>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id === "discountedProducts") {
                      value = !_.isEmpty(row.data) ? row.data.length : 0;
                    }
                    if (column.id === "action") {
                      return (
                        <TableCell align={"center"}>
                          <Box display={"flex"} justifyContent={"center"}>
                            <IconButton
                              aria-label="view"
                              onClick={() =>
                                setOpenViewDialog({
                                  open: true,
                                  item: row,
                                })
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                            <IconButton
                              aria-label="edit"
                              onClick={() =>
                                setOpenEditDialog({
                                  open: true,
                                  item: row,
                                })
                              }
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() =>
                                handleOpenConfirmationModal({
                                  onPress: () => _deleteItem(row),
                                  title: "Delete Record",
                                  msg: "Do you want to delete this record?",
                                })
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell key={column.id} align={"center"}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/*<Fab color="primary" aria-label="add" className={classes.fab}*/}
      {/*     onClick={() => setOpenAddDialog(true)}>*/}
      {/*    <AddIcon/>*/}
      {/*</Fab>*/}
      <PageTypeDialog />
      <ProductMasterAdd
        fetchData={_getProducts}
        open={openAddDialog}
        close={closeAddDialog}
      />
      <ImportExcel
        db={"productMasters"}
        open={openImportDialog}
        close={() => setOpenImportDialog(false)}
      />
      {openViewDialog ? (
        <ProductMasterView
          open={openViewDialog.open}
          close={closeViewDialog}
          item={openViewDialog.item}
        />
      ) : null}
      {openEditDialog ? (
        <ProductMasterEdit
          open={openEditDialog.open}
          close={closeEditDialog}
          item={openEditDialog.item}
          fetchData={_getProducts}
        />
      ) : null}
      {openConfirmationModal ? (
        <ConfirmationDialog
          open={openConfirmationModal}
          onClose={handleCloseConfirmationModal}
          onConfirm={openConfirmationModal.onPress}
          title={"Delete Record"}
          msg={openConfirmationModal.msg}
        />
      ) : null}
      {/* MUI Dialog (Modal) */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Delete Records by Lab</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Select Lab</InputLabel>
            <Select
              value={selectedLab}
              onChange={(e) => setSelectedLab(e.target.value)}
            >
              {labNames.map((lab, index) => (
                <MenuItem key={index} value={lab}>
                  {lab}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => _deleteAllRecordsByLab()} color="error" disabled={!selectedLab}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
