import React, { useEffect, useMemo, useState } from "react";
// @material-ui/core components
import {
  Box,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateUtils from "@date-io/moment";
import { useSelector } from "react-redux";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
// import Table from "../../components/Table/Table.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";

import CustomInput from "../../components/CustomInput/CustomInput";
import People from "@material-ui/icons/People";
import { EnhancedTableHead } from "../../components/Table/EnhancedTableHead";
import _ from "lodash";
import axios from "axios";
import { GetAllAccountsAPI } from "../../config/api";
import Button from "../../components/CustomButtons/Button.js";
import PointToPointChallan from "./PointToPointChallan";
import RoundOffChallan from "./RoundOffChallan";
import PointToPointBill from "./PointToPointBill";
import RoundOffBill from "./RoundOffBill";
import LedgerList from "./LedgerList";
import RoundOff from "./RoundOff";
import PointToPoint from "./PointToPoint";
import Ledger from "./Ledger";
import { toast } from "react-toastify";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));
const columns = [
  // { id: "name", label: "Name" },
  { id: "accountCode", label: "Account Code" },
  { id: "name", label: "Account Name" },
  // { id: "telephoneOffice", label: "Telephone Office" },
  { id: "city", label: "City" },
  // { id: "state", label: "State" },       
  // { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];
export default function AccountingList() {
  const classes = useStyles();
  const authToken = useSelector((state) => state.userReducer.authToken);

  const [accounts, setAccounts] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [selectedItem, setSelectedItem] = useState(null);
  console.log("selected item", selectedItem)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const [tota, setTota] = useState(0);
  const [openPointToPointChallanDialog, setOpenPointToPointChallanDialog] =
    useState(false);
  const [openPointToPointBillDialog, setOpenPointToPointBillDialog] =
    useState(false);
  const [openPTPTypeSelectionDialog, setOpenPTPTypeSelectionDialog] =
    useState(false);
  const [openRoundOffTypeSelectionDialog, setOpenRoundOffTypeSelectionDialog] =
    useState(false);
  const [openRoundOffDialog, setOpenRoundOffDialog] = useState(false);
  const [openRoundOffChallanDialog, setOpenRoundOffChallanDialog] =
    useState(false);
  const [openRoundOffBillDialog, setOpenRoundOffBillDialog] = useState(false);
  const [openLedgerDialog, setOpenLedgerDialog] = useState(false);
  const [openLedgerSelectionDialog, setOpenLedgerSelectionDialog] =
    useState(false);

  // const [startDate, setStartDate] = useState(null);
  const [startDate, setStartDate] = useState(new Date('2024-01-01T00:00:00'));
  // const [endDate, setEndDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date('2026-03-31T00:00:00'));

  const [PTPType, setPTPType] = useState("challan");
  const [ledgerType, setLedgerType] = useState("Challan");
  let userInfo = useSelector((state) => state.userReducer.userInfo);

  useEffect(() => {
    _getAccounts();
  }, [openRoundOffChallanDialog, openLedgerDialog, page, rowsPerPage]);

  // async function _getAccounts() {
  //   try {
  //     let res = await axios.get(GetAllAccountsAPI(userInfo.entity), {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     });
  //     setAccounts(res.data.accounts);
  //   } catch (err) {
  //     // toast.error(('Cannot fetch data'), {
  //     //   position: "top-center",
  //     //   autoClose: 1500,
  //     //   hideProgressBar: false,
  //     //   closeOnClick: true,
  //     //   pauseOnHover: true,
  //     //   draggable: true,
  //     //   progress: undefined,
  //     //   theme: "light",
  //     // });  
  //   }
  // }

  const _getAccounts = useMemo(() => async (page, rowsPerPage) => {
    let skipm = page + 2

    try {
      let query = `&limit=${rowsPerPage}&skip=${skipm}`;

      let res = await axios.get(
        GetAllAccountsAPI(userInfo.entity) + query,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(userInfo.entity, "This is user entity")
      setAccounts(res.data.accounts);
      setTota(res.data.total)

    } catch (err) {
      console.log("Error:  ", (err))
      // toast.error(("Something went wrong, Please Contact Admin"), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }

  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const RoundOffTypeSelectionDialog = () => {
    let types = [
      {
        label: "Challan",
        value: "challan",
      },
      {
        label: "Bill",
        value: "bill",
      },
    ];
    return (
      <Dialog
        onClose={() => setOpenRoundOffTypeSelectionDialog(false)}
        aria-labelledby="simple-dialog-title"
        open={openRoundOffTypeSelectionDialog}
      >
        <DialogTitle id="simple-dialog-title">Select Type</DialogTitle>
        <List>
          {types.map(({ label, value }) => (
            <ListItem
              button
              onClick={() => {
                if (value === "bill") {
                  setOpenRoundOffBillDialog(true);
                } else {
                  setOpenRoundOffChallanDialog(true);
                }
                setOpenRoundOffTypeSelectionDialog(false);
              }}
              key={value}
            >
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  };

  let data = accounts;
  if (searchTerm !== "") {
    data = _.filter(data, (i) => {
      return (
        (i.name && i.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (i.accountCode &&
          i.accountCode.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    });
  }
  function handleSearch() {
    setSearchTerm(search);
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <Grid container style={{ alignItems: "center" }}>
              <GridItem xs={8} sm={8} md={8}>
                <h4 className={classes.cardTitleWhite}>Accounts</h4>
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <CustomInput
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    style: {
                      color: "white",
                    },
                    placeholder: "Search",
                    value: search,
                    // onChange: (e) => setSearch(e.target.value),
                    onChange: (e) => {
                      setSearch(e.target.value);
                      if (e.target.value === "" || e.target.value === null) {
                        _getAccounts(page, rowsPerPage);
                      }

                    },
                    // onChange: (e) => handleInputChange(e),
                    onKeyDown: (e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        handleSearch();
                      }
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        {/* <People /> */}
                        <IconButton color={"secondary"} onClick={(e) => {
                          // setSearchTerm(e.target.value),
                          // handleKeyPress(e)
                          // handleKeyPress()
                          handleSearch()


                        }}>
                          <Search />
                        </IconButton>

                      </InputAdornment>
                    ),
                  }}
                />
                {/* <IconButton color={"secondary"} onClick={(e) => {
                  // setSearchTerm(e.target.value),
                  // handleKeyPress(e)
                  // handleKeyPress()
                  handleSearch()


                }}>
                  <Search />
                </IconButton> */}
              </GridItem>
            </Grid>
          </CardHeader>

          <CardBody>
            <Table stickyHeader aria-label="sticky table">
              <EnhancedTableHead
                columns={columns}
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          if (column.id === "action") {
                            return (
                              <TableCell align={"center"}>
                                <Box display={"flex"} justifyContent={"center"}>
                                  <Button
                                    type="button"
                                    color="primary"
                                    onClick={() => {
                                      setSelectedItem(row);
                                      setOpenPTPTypeSelectionDialog(true);
                                    }}
                                  >
                                    Point to point
                                  </Button>
                                  <Button
                                    type="button"
                                    color="primary"
                                    onClick={() => {
                                      setSelectedItem(row);
                                      setOpenRoundOffDialog(true);
                                    }}
                                  >
                                    Round Off
                                  </Button>
                                  <Button
                                    type="button"
                                    color="primary"
                                    onClick={() => {
                                      setSelectedItem(row);
                                      setOpenLedgerSelectionDialog(true);
                                    }}
                                  >
                                    Ledger
                                  </Button>
                                </Box>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={column.id} align={"center"}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardBody>
        </Card>
      </GridItem>
      {selectedItem && openPointToPointChallanDialog ? (
        <PointToPointChallan
          open={openPointToPointChallanDialog}
          item={selectedItem}
          close={() => setOpenPointToPointChallanDialog(false)}
          startDate={startDate}
          endDate={endDate}
        />
      ) : null}
      {selectedItem && openPointToPointBillDialog ? (
        <PointToPointBill
          open={openPointToPointBillDialog}
          item={selectedItem}
          close={() => setOpenPointToPointBillDialog(false)}
          startDate={startDate}
          endDate={endDate}
        />
      ) : null}
      {selectedItem && openRoundOffChallanDialog ? (
        <RoundOffChallan
          open={openRoundOffChallanDialog}
          item={selectedItem}
          close={() => setOpenRoundOffChallanDialog(false)}
          startDate={startDate}
          endDate={endDate}
        />
      ) : null}
      {selectedItem && openRoundOffBillDialog ? (
        <RoundOffBill
          open={openRoundOffBillDialog}
          item={selectedItem}
          close={() => setOpenRoundOffBillDialog(false)}
        />
      ) : null}
      {selectedItem && openLedgerDialog ? (
        <LedgerList
          open={openLedgerDialog}
          item={selectedItem}
          close={() => setOpenLedgerDialog(false)}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          ledgerType={ledgerType}
        />
      ) : null}
      {selectedItem && openRoundOffDialog ? (
        <RoundOff
          open={openRoundOffDialog}
          item={selectedItem}
          close={() => setOpenRoundOffDialog(false)}
          openRoundOffChallanDialog={openRoundOffChallanDialog}
          setOpenRoundOffChallanDialog={setOpenRoundOffChallanDialog}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      ) : null}
      {selectedItem && openPTPTypeSelectionDialog ? (
        <PointToPoint
          item={selectedItem}
          PTPType={PTPType}
          openPTPTypeSelectionDialog={openPTPTypeSelectionDialog}
          setOpenPTPTypeSelectionDialog={setOpenPTPTypeSelectionDialog}
          setPTPType={setPTPType}
          setOpenPointToPointBillDialog={setOpenPointToPointBillDialog}
          setOpenPointToPointChallanDialog={setOpenPointToPointChallanDialog}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      ) : null}

      {selectedItem && openLedgerSelectionDialog ? (
        <Ledger
          item={selectedItem}
          openLedgerSelectionDialog={openLedgerSelectionDialog}
          setOpenLedgerSelectionDialog={setOpenLedgerSelectionDialog}
          setOpenLedgerDialog={setOpenLedgerDialog}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          ledgerType={ledgerType}
          setLedgerType={setLedgerType}
        />
      ) : null}
      {/* <RoundOff
        item={selectedItem}
      /> */}
      <RoundOffTypeSelectionDialog />
    </GridContainer>
  );
}
