import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  IconButton,
  InputAdornment,
  makeStyles,
  Box,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@material-ui/core";

import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CustomInput from "../../components/CustomInput/CustomInput";
import People from "@material-ui/icons/People";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import PurchaseAdd from "./PurchaseAdd";
import PurchaseView from "./PurchaseView";
import PurchaseEdit from "./PurchaseEdit";
import axios from "axios";

import {
  DeletePurchaseAPI,
  GetAllPurchasesAPI,
  UpdateReadyMasterAPI,
  GetReadyMasterAPI,
} from "../../config/api";

import { ConfirmationDialog } from "../../components/common";
import Checkbox from "@material-ui/core/Checkbox";
import _ from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Search } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

export default function TableList(props) {
  let types = ["Single Product", "Range Product"];
  let pageTypes = ["Normal", "Wide"];
  const columns = [
    {
      id: "billNo",
      label: "Bill No",
      format: (value) => (value ? value : "NA"),
    },
    {
      id: "challanNo",
      label: "Challan No",
      format: (value) => (value ? value : "NA"),
    },
    {
      id: "party",
      label: "Party",
      format: (value) => (value ? value : "NA"),
    },
    {
      id: "products",
      label: "Products",
      format: (value) => (value ? value.length : "NA"),
    },
    {
      id: "purchaseDate",
      label: "Date",
      format: (value) => (value ? moment(value).format("DD-MM-YYYY") : "NA"),
    },
    {
      id: "netAmount",
      label: "Net Amount",
      format: (value) =>
        Number(value).toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR",
        }),
    },
    {
      id: "gstAmount",
      label: "GST",
      // format: (value) => (value ? value : "NA"),
      format: (value) =>
        Number(value).toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR",
        }),
    },
    {
      id: "totalAmount",
      label: "Total Amount",
      format: (value) =>
        Number(value).toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR",
        }),
    },

    { id: "action", label: "Action" },
  ];

  const classes = useStyles();
  const userInfo = useSelector((state) => state.userReducer.userInfo);
  const authToken = useSelector((state) => state.userReducer.authToken);
  const [purchases, setPurchases] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [tota, setTota] = useState("asc");
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(null);
  const [selectedType, setSelectedType] = useState(types[0]);
  const [openPurchaseTypeDialog, setOpenPurchaseTypeDialog] = useState(false);
  const [openPageTypeDialog, setOpenPageTypeDialog] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    _getPurchases(page, rowsPerPage);
  }, [page, rowsPerPage]);

  async function _getPurchases(page, rowsPerPage) {
    try {
      if (rowsPerPage === undefined || rowsPerPage === null || rowsPerPage === NaN) {
        rowsPerPage = 10;
      }

      if (page === undefined || page === null || page === NaN) {
        page = 0;
      }

      let query = `&sortBy=createdAt:desc&limit=${rowsPerPage}&skip=${page * rowsPerPage}&search=${searchTerm}`;
      let res = await axios.get(GetAllPurchasesAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("aaya>>>", res.data)
      setPurchases(res.data.purchases);
      setTota(res.data.total)
    } catch (e) {
      console.log('Purchase Error',e);
    }
  }

  async function _deleteItem(item) {
    try {
      let res = await axios.delete(DeletePurchaseAPI(item._id), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log('item', item)

      // if(item.products != null){
      //   item.products.map(async (i) => {
      //     await axios.get(GetReadyMasterAPI(i._id), {
      //       headers: {
      //         Authorization: `Bearer ${authToken}`,
      //       },
      //     });
      //   });
      // }
      
      // window.location.reload();
      _getPurchases();
      toast.info(("Deleted Successfully"), {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (e) {
      console.log(e, "errorrrrr");
      toast.error(("Something went wrong, Please Contact Admin"), {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    }
  }

  const PurchaseTypeDialog = () => {
    return (
      <Dialog
        onClose={() => setOpenPurchaseTypeDialog(false)}
        aria-labelledby="simple-dialog-title"
        open={openPurchaseTypeDialog}
      >
        <DialogTitle id="simple-dialog-title">Select Purchase Type</DialogTitle>
        <List>
          {types.map((item) => (
            <ListItem
              button
              onClick={() => {
                setSelectedType(item);
                setOpenPurchaseTypeDialog(false);
                setOpenAddDialog(true);
              }}
              key={item}
            >
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  };

  const PageTypeDialog = () => {
    return (
      <Dialog
        onClose={() => setOpenPageTypeDialog(false)}
        aria-labelledby="simple-dialog-title"
        open={openPageTypeDialog}
      >
        <DialogTitle id="simple-dialog-title">Select Page Type</DialogTitle>
        <List>
          {pageTypes.map((item) => (
            <ListItem
              button
              onClick={() => {
                setOpenPageTypeDialog(false);
                let tmp = [];
                selectedRows.map((o) => {
                  let obj = _.find(purchases, { _id: o });
                  tmp.push(obj);
                });
                props.history.push("/admin/purchase-barcodes", {
                  purchases: tmp,
                });
              }}
              key={item}
            >
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  };

  const handleOpenConfirmationModal = (object) => {
    setOpenConfirmationModal(object);
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function closeAddDialog() {
    setOpenAddDialog(false);
  }

  function closeEditDialog() {
    setOpenEditDialog(null);
  }

  function closeViewDialog() {
    setOpenViewDialog(null);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearch = async () => {
    setSearchTerm(search)
    await _getPurchases();
  };


  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4 className={classes.cardTitleWhite}>Purchases</h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Button
                  color="secondary"
                  style={{ marginRight: 10 }}
                  onClick={() =>
                    handleOpenConfirmationModal({
                      title: "Print barcode",
                      msg: "Do you want to print barcodes?",
                      onPress: () => setOpenPageTypeDialog(true),
                    })
                  }
                >
                  Print Barcodes
                </Button>

                <CustomInput
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    style: {
                      marginTop: 0,
                      marginRight: 10,
                      color: "white",
                    },
                    placeholder: "Search",
                    value: search,
                    onChange: (e) => {
                      setSearch(e.target.value);
                    },
                    onKeyDown: (e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        handleSearch();
                      }
                    }
                  }}
                />
                <IconButton color={"secondary"} onClick={(e) => {
                  handleSearch()
                }}>
                  <Search />
                </IconButton>
                <IconButton
                  aria-label="add"
                  style={{ color: "white" }}
                  onClick={() => setOpenPurchaseTypeDialog(true)}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </div>
          </CardHeader>

          <CardBody>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <Checkbox
                    style={{
                      color: "#512da8",
                    }}
                    onChange={(e) => {
                      let c = [];
                      if (e.target.checked) {
                        purchases.map((i) => {
                          c.push(i._id);
                        });
                      }
                      setSelectedRows(c);
                    }}
                    checked={
                      selectedRows.length > 0 &&
                      purchases.length === selectedRows.length
                    }
                    inputProps={{ "aria-labelledby": -1 }}
                  />
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={"center"}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {purchases
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            style={{
                              color: "#512da8",
                            }}
                            onChange={() => {
                              let copy = _.clone(selectedRows);
                              if (_.includes(copy, row._id)) {
                                let index = _.indexOf(copy, row._id);
                                copy.splice(index, 1);
                              } else {
                                copy.push(row._id);
                              }
                              setSelectedRows(copy);
                            }}
                            checked={_.includes(selectedRows, row._id)}
                            inputProps={{ "aria-labelledby": row._id }}
                          />
                        </TableCell>
                        {columns.map((column) => {
                          const value = row[column.id];

                          if (column.id === "party") {
                            return (
                              <TableCell key={column.id} align={"center"}>
                                {column.format
                                  ? column.format(value?.name)
                                  : value?.name}
                              </TableCell>
                            );
                          }
                          if (column.id === "action") {
                            return (
                              <TableCell align={"center"}>
                                <Box display={"flex"} justifyContent={"center"}>
                                  <IconButton
                                    onClick={() =>
                                      setOpenViewDialog({
                                        open: true,
                                        item: row,
                                      })
                                    }
                                    aria-label="view"
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                  {/* <IconButton
                                    aria-label="edit"
                                    onClick={() =>
                                      setOpenEditDialog({
                                        open: true,
                                        item: row,
                                      })
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton> */}
                                  <IconButton
                                    onClick={() =>
                                      handleOpenConfirmationModal({
                                        title: "Delete Record",
                                        msg: "Do you want to delete this record?",
                                        onPress: () => _deleteItem(row),
                                      })
                                    }
                                    aria-label="delete"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={column.id} align={"center"}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={tota}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardBody>
        </Card>
      </GridItem>
      {/*<Fab color="primary" aria-label="add" className={classes.fab}*/}
      {/*     onClick={() => setOpenPurchaseTypeDialog(true)}>*/}
      {/*    <AddIcon/>*/}
      {/*</Fab>*/}
      <PurchaseTypeDialog />
      <PageTypeDialog />
      {openAddDialog ? (
        <PurchaseAdd
          fetchData={_getPurchases}
          open={openAddDialog}
          type={selectedType}
          close={closeAddDialog}
        />
      ) : null}
      {openEditDialog ? (
        <PurchaseEdit
          fetchData={_getPurchases}
          open={openEditDialog.open}
          item={openEditDialog.item}
          close={closeEditDialog}
        />
      ) : null}
      {openViewDialog ? (
        <PurchaseView
          open={openViewDialog.open}
          close={closeViewDialog}
          item={openViewDialog.item}
        />
      ) : null}
      {openConfirmationModal ? (
        <ConfirmationDialog
          open={openConfirmationModal}
          onClose={handleCloseConfirmationModal}
          onConfirm={openConfirmationModal.onPress}
          title={openConfirmationModal.title}
          msg={openConfirmationModal.msg}
        />
      ) : null}
    </GridContainer>
  );
}
